
import _ from 'underscore';
import {SecurityLevel} from '@/model/security_level';

const common = [{
    idx: 1,
    key: 'securityLevel',
    label: 'Security Level:'
}, {
    idx: 2,
    key: 'username',
    label: 'Username:'
}, {
    idx: 3,
    key: 'ubcId',
    label: 'UBC ID:'
}, {
    idx: 4,
    key: 'name',
    label: 'Name:'
}, {
    idx: 7,
    key: 'suffix',
    label: 'Suffix:'
}, {
    idx: 8,
    key: 'local',
    label: 'Local Union:'
}, {
    idx: 9,
    key: 'title',
    label: 'Title:'
}, {
    idx: 10,
    key: 'email',
    label: 'Email Address:'
}, {
    idx: 11,
    key: 'phone',
    label: 'Mobile Phone:'
}, {
    idx: 12,
    key: 'emergencyContact',
    label: 'Emergency Contact:'
}, {
    idx: 13,
    key: 'organization',
    label: 'Organization:'
}, {
    idx: 14,
    key: 'vaccineVerified',
    label: 'COVID-19 Vaccine Verified:'
}, {
    idx: 15,
    key: 'status',
    label: 'User Account Status:'
}, {
    idx: 16,
    key: 'comments',
    label: 'Comments:'
}];

function cloneCommonFields() {
    return _.map(common, (f) => _.clone(f));
}

function insertField(fields, key, field) {
    const idx = _.findIndex(fields, (f) => f.key === key);
    fields.splice(idx + 1, 0, field);
}


function getAdminFields() {
    const fields = cloneCommonFields();

    return _.chain(fields)
        .filter((f) => !_.contains(['comments', 'local', 'vaccineVerified'], f.key))
        .sortBy((f) => f.idx)
        .map((f) => _.omit(f, 'idx'))
        .value();
}

function getInstructorFields() {
    const fields = cloneCommonFields();

    return _.chain(fields)
        .sortBy((f) => f.idx)
        .map((f) => _.omit(f, 'idx'))
        .value();
}

function getMentorFields() {
    const fields = cloneCommonFields();

    fields.push({
        idx: 13.1,
        key: 'mentorStatus',
        label: 'Mentor Status:'
    });

    return _.chain(fields)
        .sortBy((f) => f.idx)
        .map((f) => {

            // Rename the organization field to be Contractor per #56539
            if (f.key === 'organization') {
                f.label = 'Contractor:';
            }

            return _.omit(f, 'idx');
        })
        .value();
}

function getTraineeFields() {
    const fields = cloneCommonFields();

    fields.push({
        idx: 8.1,
        key: 'unionStatus',
        label: 'Union Status:'
    });

    fields.push({
        idx: 8.2,
        key: 'classification',
        label: 'Union Classification:'
    });

    fields.push({
        idx: 13.1,
        key: 'mentor',
        label: 'Mentor:'
    });

    fields.push({
        idx: 13.2,
        key: 'traineeStatus',
        label: 'Trainee Status:'
    });

    fields.push({
        idx: 13.3,
        key: 'track',
        label: 'Track:'
    });

    return _.chain(fields)
        .sortBy((f) => f.idx)
        .map((f) => {

            // Rename the organization field to be Contractor per #56539
            if (f.key === 'organization') {
                f.label = 'Contractor:';
            }

            return _.omit(f, 'idx');
        })
        .value();
}


export default function getFields(securityLevel) {
    switch (securityLevel) {
        case SecurityLevel.ADMINISTRATOR:
            return getAdminFields();

        case SecurityLevel.INSTRUCTOR:
            return getInstructorFields();

        case SecurityLevel.MENTOR:
            return getMentorFields();

        case SecurityLevel.TRAINEE:
        default:
            return getTraineeFields();
    }
}

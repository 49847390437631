<template>
    <b-card no-body>
        <template v-slot:header>
            <strong>Instructor Registrations for {{instructor.firstName}} {{instructor.lastName}}</strong>
        </template>
        <b-card-body>
            <instructor-registrations-snapshot :instructorId="instructor.id"/>
        </b-card-body>
    </b-card>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import {Instructor, User, NULL_USER} from '@/model/user';
import InstructorRegistrationsSnapshot from '@/views/private/users/snippet/InstructorRegistrationsSnapshot.vue';

@Component({
    components: {
        InstructorRegistrationsSnapshot
    }
})

export default class InstructorProgramSnapshot extends Vue {
    @Prop({type: [Instructor, User], default: NULL_USER}) instructor;
}
</script>
<style scoped>

</style>
<template>
    <div>
        <b-row>
            <b-col>
                <b-img class="mb-2 profile-picture" :src="photoUrl" fluid/>
            </b-col>
        </b-row>
        <b-row v-if="canUploadPhoto">
            <b-col>
                <b-link @click="showModal">upload photo</b-link>
            </b-col>
        </b-row>
        <b-modal id="upload-photo-modal" centered :title="modalTitle" no-close-on-backdrop>
            <b-row>
                <b-col>
                    <b-file size="sm"
                            v-model="pictureFile"
                            placeholder="Select a picture to Upload"
                            accept="image/*"
                            @change="setupCropper"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-center">
                    <div class="image-container">
                        <img ref="sourceFile" :src="fileUrl"/>
                    </div>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <b-button-group size="sm">
                    <b-button variant="success" @click="doUpload" :disabled="null == pictureFile">Upload</b-button>
                    <b-button variant="secondary" @click="$bvModal.hide('upload-photo-modal')">Cancel</b-button>
                </b-button-group>
            </template>
        </b-modal>
    </div>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import {sprintf} from 'sprintf-js';
import {User} from '@/model/user';
import _ from 'underscore';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import {getUnixTime} from 'date-fns';
import userDao from '@/dao/user_dao';
import {errorModalOptions} from "../../../util/formatters";

const BASE_URL = '/api/v1/user/photo/%d?rev=%d';

@Component
export default class ProfilePicture extends Vue {
    @Prop({type: User}) value;

    pictureFile = null;

    cropper = null;
    fileUrl = null;
    preview = null;

    pictureRev = new Date();

    get user() {
        return this.value;
    }

    get revision() {
        return getUnixTime(this.pictureRev);
    }

    set revision(date) {
        this.pictureRev = date;
    }

    get photoUrl() {
        return sprintf(BASE_URL, parseInt(this.user.id, 10) || 0, this.revision);
    }

    get canUploadPhoto() {
        // TODO: logic to determine whether or not an upload
        //       will be allowed

        return null === this.user.ubcId;
    }

    get modalTitle() {
        return sprintf('Upload New Photo for %s %s', this.user.firstName, this.user.lastName);
    }

    async doUpload() {
        try {
            await userDao.savePhoto(this.user.id, this.preview);
            this.revision = new Date();
            this.$bvModal.hide('upload-photo-modal');
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    showModal() {
        this.clearCropper();
        this.$bvModal.show('upload-photo-modal');
    }

    clearCropper() {

        if (this.cropper) {
            this.cropper.destroy();
        }

        if (this.fileUrl) {
            URL.revokeObjectURL(this.fileUrl);
        }

        this.fileUrl = null;
        this.preview = null;
    }


    setupCropper() {

        setTimeout(() => {

            this.clearCropper();

            if (!this.pictureFile) {
                return;
            }

            this.fileUrl = URL.createObjectURL(this.pictureFile);

            setTimeout(this.setupCropperInstance.bind(this), 300);

        }, 300);
    }

    setupCropperInstance() {
        this.cropper = new Cropper(this.$refs.sourceFile, {
            aspectRatio: 1,
            zoomable: false,
            center: true,
            scalable: false,
            crop: _.debounce(this.updatePreview, 300)
        });
    }

    updatePreview() {

        const canvas = this.cropper.getCroppedCanvas({
            maxWidth: 500,
            maxHeight: 500
        });

        this.preview = canvas.toDataURL('image/jpg');
    }
}
</script>
<style scoped>
    .image-container {
        display: inline-block;
    }

    .image-container img {
        max-width: 100%;
    }

</style>
<style>
    img.profile-picture {
        border: 1px solid #000;
        border-radius: 5px;
        box-shadow: 0 3px 3px #000;
    }
</style>
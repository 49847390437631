<template>
    <b-card no-body>
        <b-tabs small card>
            <b-tab :title="formHeading"
                   :active="isActive(0)"
                   lazy>
                <b-row>
                    <b-col cols="md-3" class="text-center">
                        <profile-picture v-model="selectedUser"/>
                    </b-col>
                    <b-col cols="md-9">
                        <profile-table :user="selectedUser"
                                       @editSaved="refreshUser"
                                       @startEdit="editMode=true"
                                       @cancelEdit="editMode=false"
                                       :key="profileKey"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Registrations"
                   v-if="showSchedule"
                   :active="isActive(1)"
                   lazy>
                <b-row>
                    <b-col class="p-0 m-0">
                        <b-card no-body v-if="selectedUser.isAMentor()">
                            <b-tabs content-class="mt-3" card>
                                <b-tab title="Trainees">
                                    <template v-for="t in trainees">
                                        <trainee-program-snapshot :trainee="t"/>
                                    </template>
                                </b-tab>
                                <b-tab title="Schedule">
                                    <mentor-program-snapshot :mentor="selectedUser" :trainees="trainees"/>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                        <b-card title="Schedule" v-if="selectedUser.isATrainee()" no-body>
                            <trainee-program-snapshot :trainee="selectedUser" :expanded="true" @updateTab="updateActiveTab"/>
                        </b-card>
                        <instructor-program-snapshot v-if="selectedUser.isAnInstructor()" :instructor="selectedUser"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Travel"
                   v-if="showTravel"
                   :active="isActive(2)"
                   lazy>
                <travel-history :user="selectedUser"/>
            </b-tab>
        </b-tabs>
    </b-card>
</template>
<script>

import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {NULL_USER, NULL_TRAINEE} from '@/model/user';
import ProfileTable from '@/views/private/users/ProfileTable.vue';
import {sprintf} from 'sprintf-js';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import _ from 'underscore';
import ProfilePicture from './ProfilePicture.vue';
import RegistrationsSnapshot from '@/views/private/users/snippet/RegistrationsSnapshot.vue';
import TraineeProgramSnapshot from '@/views/private/users/snippet/TraineeProgramSnapshot.vue';
import InstructorProgramSnapshot from '@/views/private/users/snippet/InstructorProgramSnapshot.vue';
import MentorProgramSnapshot from '@/views/private/users/snippet/MentorProgramSnapshot.vue';
import TravelHistory from '@/views/private/users/TravelHistory.vue';
import {errorToastOptions} from "../../../util/formatters";

const BASE_CRUMBS = [
    Breadcrumb.create('Administration', {name: 'adminMain'}, false),
    Breadcrumb.create('Users', {name: 'adminUsers'}, false)
];

function shouldIBeHere(activeUser, user) {

    if (activeUser.id === user.id) {
        return true;
    }


    if (activeUser.isAnAdministrator()) {
        // Administrators can view everyone
        return true;
    }

    if (activeUser.isAnInstructor()) {
        // Instructors can only view themselves, all mentors and all trainees
        return !user.isAnAdministrator() && (user.isAMentor() || user.isATrainee());
    }

    if (activeUser.isAMentor()) {
        // Mentors can only see themselves and any of their trainees
        return (!user.isAnAdministrator() && !user.isAnInstructor()) ||
            (user.isAMentor() && user.id === activeUser.id) ||
            (user.isATrainee() && _.contains(activeUser.traineeIds, user.id));
    }

    if (activeUser.isATrainee()) {
        // Trainees can only see themselves and their mentor
        return (user.isAMentor() && activeUser.mentorId === user.id) ||
                activeUser.id === user.id;
    }
}

async function routeHandler(to, from, next) {
    const currentUser = store.getters['userSession/getUser'];

    if (!currentUser) {
        return next(false);
    }

    if ('new' === to.params.id) {

        if (!currentUser || !currentUser.isAnAdministrator()) {
            return next('/admin');
        }

        store.commit('setBreadcrumbs', _.union(BASE_CRUMBS, [Breadcrumb.create('New User', null, true)]));

        store.commit('users/setUser', NULL_TRAINEE.clone());

        await loadNecessary(currentUser);

        return next();
    }

    try {
        const user = currentUser.id === to.params.id ? currentUser : await store.dispatch('users/loadUser', to.params.id);
        // TODO: Make an ajax call to check and see if this user can view this user, as to not leak any more than just the ID
        // Am I supposed to be here?!
        if (!shouldIBeHere(currentUser, user, next.bind(null, "/admin"))) {
            return next("/admin");
        }

        store.commit('users/setUser', user);

        const baseCrumbs = currentUser.isATrainee() ? [BASE_CRUMBS[0]] : BASE_CRUMBS;

        store.commit('setBreadcrumbs', _.union(baseCrumbs, [
            Breadcrumb.create(sprintf('%s %s', user.firstName, user.lastName), null, true)
        ]));

        switch (true) {
            case user.isAMentor():
                await store.dispatch('trainees/loadTraineesForMentor', user.id);
                await store.dispatch('users/pastTraineeDetails', user.id);
                break;

            case user.isATrainee():
                await store.dispatch('mentors/loadMentor', user.mentorId);
                await store.dispatch('tracks/loadTrack', user.trackId);
                break;

            default:
                break;
        }

        user.rollback();

        await loadNecessary(currentUser);

        next();
    }
    catch (error) {
        //Spin up a Vue for toast
        const vm = new Vue();
        vm.$bvToast.toast(error.message, errorToastOptions);
    }
}

async function loadNecessary(currentUser) {
    let resources = [
        'tracks/loadTracks',
        'registration/loadRegistrableUserIds',
        'registration/loadCancelableUserIds',
        'organizations/loadOrganizations',
        'users/loadUserStatuses',
        'trainees/loadTraineeStatuses',
        'common/loadStates'
    ];
    if (currentUser.isAnAdministrator()) {
        resources.push('mentors/loadUserMentors');
    }
    _.each(resources, (action) => {
        store.dispatch(action)
            .catch(error => {
                const vm = new Vue();
                vm.$bvToast.toast(error.message, errorToastOptions);
            });
    });
}

@Component({
    components: {
        ProfileTable,
        ProfilePicture,
        RegistrationsSnapshot,
        TraineeProgramSnapshot,
        InstructorProgramSnapshot,
        MentorProgramSnapshot,
        TravelHistory
    }
})
export default class Profile extends Vue {
    @Prop({type: [Number, String], default: 0}) activeTabIndex;
    theActiveTab = 0;

    profileKey = 0;

    async refreshUser() {
        if (!this.selectedUser || !this.selectedUser.id) {
            return;
        }
        try {
            const user = await store.dispatch('users/loadUser', this.selectedUser.id);
            store.commit('users/setUser', user);
            switch (true) {
                case user.isAMentor():
                    await store.dispatch('trainees/loadTraineesForMentor', user.id);
                    await store.dispatch('users/pastTraineeDetails', this.selectedUser.id);
                    break;
                case user.isATrainee():
                    await store.dispatch('mentors/loadMentor', user.mentorId);
                    await store.dispatch('tracks/loadTrack', user.trackId);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async mounted() {
        //Kinda hacky, but can't use router to push to a new tab from a subcomponent, so creating a local non-prop to alter
        this.theActiveTab = this.activeTabIndex;
    }

    isActive(tabIndex) {
        return this.activeTab === tabIndex;
    }

    updateActiveTab(newIndex) {
        console.log(newIndex);
        this.theActiveTab = newIndex;
    }

    get activeTab() {
        if (+this.theActiveTab === 1) {
            //Registration
            return this.showSchedule ? 1 : 0;
        }
        else if (+this.theActiveTab === 2) {
            //Travel
            return this.showTravel ? 2 : 0;
        }
        else {
            //Default
            return 0;
        }
    }

    get editMode() {
        return this.selectedUser.editMode;
    }

    set editMode(em) {
        this.selectedUser.editMode = em;
    }

    get selectedUser() {
        let user = this.$store.getters['users/user'];
        if (NULL_USER.equals(user)) {
            user = NULL_TRAINEE.clone();
            user.editMode = true;
        }
        return user;
    }

    get showSchedule() {
        return !this.selectedUser.isNew &&
            (this.selectedUser.isAMentor() || this.selectedUser.isATrainee() || this.selectedUser.isAnInstructor());
    }

    get showTravel() {
        return !this.selectedUser.isNew && !this.selectedUser.isAnAdministrator();
    }

    get trainees() {

        const trainees = this.selectedUser.isAMentor() ? this.$store.getters['trainees/trainees'] : [];

        return _.chain(trainees)
            .unique((t) => t.id)
            .sortBy((t) => t.firstName)
            .sortBy((t) => t.lastName)
            .value();
    }

    get tracks() {
        return _.chain(this.trainees)
            .pluck('trackId')
            .uniq((id) => id)
            .map((id) => this.$store.getters['tracks/getTrackById'](id))
            .value();
    }

    get formHeading() {
        const user = this.selectedUser;

        return user.isNewUser() ? 'Profile for New User' : sprintf('%s Profile for %s %s',
            user.securityLevel.toString(),
            user.firstName,
            user.lastName
        );
    }

    getTrack(trackId) {
        return _.find(this.tracks, (t) => t.id === trackId);
    }

    beforeRouteEnter(to, from, next) {
        routeHandler(to, from, next);
    }

    beforeRouteUpdate(to, from, next) {
        routeHandler(to, from, next);
    }

    @Watch('selectedUser')
    watchSelectedUser() {
        this.profileKey++;
    }
}
</script>
<style scoped>
 ol.registration-schedule {
     margin: 0;
     padding: 0 0 0 1.25rem;

 }
</style>

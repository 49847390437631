<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col>
                    <b-button-group size="sm">
                        <b-button variant="primary" v-if="isRegistrable" @click="register" @done="closeRegistration">Register</b-button>
                        <b-button variant="primary" v-else @click="showRules">Why Can't I Register?</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong class="mt-3" v-if="schedule.length == 0">Program information is not available.</strong>
                    <ul class="registration-schedule" v-else>
                        <li v-for="reg in schedule" class="mt-2">
                            <b-link @click="getDetails(reg.session)">{{reg.session.label}}</b-link>
                            <span> - </span>
                            <b-dropdown
                                    :variant="statusVariant(reg.status)"
                                    :text=reg.status
                                    size="sm">
                                <!--Instructor sessions are never scheduled, so registration isn't done here-->
                                <template v-if="isRegistered(reg.status) && !!reg.hasRegistrationId">
                                    <b-dropdown-item
                                            @click="resend(reg)">Resend confirmation</b-dropdown-item>
                                    <b-dropdown-item
                                            @click="cancel(reg)">Cancel registration</b-dropdown-item>
                                    <b-dropdown-item
                                            v-if="allowCompleteIncomplete"
                                            @click="incomplete(reg)">Mark incomplete</b-dropdown-item>
                                </template>
                                <template v-else-if="isCompleted(reg.status) && !!reg.hasRegistrationId && allowCompleteIncomplete">
                                    <b-dropdown-item
                                            @click="incomplete(reg)">Mark incomplete</b-dropdown-item>
                                </template>
                                <template v-else-if="isIncomplete(reg.status) && !!reg.hasRegistrationId && allowCompleteIncomplete">
                                    <b-dropdown-item
                                            @click="complete(reg)">Mark complete</b-dropdown-item>
                                </template>
                                <template v-else-if="isCancelled(reg.status) && isRegistrable">
                                    <b-dropdown-item
                                            @click="registerFor(reg)">Register</b-dropdown-item>
                                </template>
                                <template v-else>
                                    <b-dropdown-item>No actions available</b-dropdown-item>
                                </template>
                            </b-dropdown>
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </b-col>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
        <!--REGISTRATION MODAL-->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 title="Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <InstructorSessionRegistration
                    :instructor="instructor"
                    @done="closeRegistration" />
        </b-modal>
        <!--RULES MODAL-->
        <b-modal :id="rulesModalId"
                 size="xl"
                 scrollable
                 ok-only
                 button-size="sm"
                 title="Registration Rules"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <registration-rules />
        </b-modal>
        <!-- CONFIRM REGISTRATION MODAL -->
        <b-modal :id="confirmRegistrationModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Confirm Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ConfirmRegistration
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @registered="registered"
                    @canceled="canceled" />
        </b-modal>
    </b-row>
</template>
<script>
import {Vue, Component, Prop} from 'vue-property-decorator';
import {format as dateFormat} from 'date-fns';
import {sprintf} from 'sprintf-js';
import store from '@/store/store';
import {NULL_USER, User, Instructor} from "@/model/user";
import {Status, RegistrationRequestType} from "@/model/registration";
import InstructorSessionRegistration from '@/views/private/instructor/InstructorSessionRegistration.vue';
import _ from 'underscore';
import RegistrationRules from '@/views/private/users/RegistrationRules.vue';
import SessionDetails from '@/views/private/tracks/SessionDetails.vue';
import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
import registrationDao from "@/dao/registration_dao";
import {errorModalOptions, errorToastOptions} from "../../../../util/formatters";
import covidRegistrationRestriction from '@/errors/covid_registration_restriction';

@Component({
    components: {
        InstructorSessionRegistration,
        RegistrationRules,
        SessionDetails,
        ConfirmRegistration
    },
    filters: {
        programDate: (date) => _.isDate(date) ? dateFormat(date, 'M/d/yyyy') : ''
    }
})

export default class InstructorRegistrationsSnapshot extends Vue {
    @Prop({type: Number}) instructorId;
    registrationRequest = null;
    session = null;

    registered() {
        this.refresh();
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    canceled() {
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    get instructor() {
        return this.$store.getters['instructor/instructor'];
    }

    get confirmRegistrationModalId() {
        return 'confirm-registration-modal-' + this.instructorId;
    }

    async registerFor(scheduleItem) {
        // console.log(scheduleItem);
        if (!this.isRegistrable || !this.isCancelled(scheduleItem.status)) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to register for this session.');
        }
        else {
            //Check for override
            if (scheduleItem.needsOverride) {
                const activeUser = store.getters['userSession/getUser'];
                if (activeUser.isAnAdministrator()) {
                    const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                        'register for a session that requires vaccine verification has not been verified. Would you ' +
                        'like to override the vaccine requirement and register this participant anyway?', {
                        title: 'Vaccine Override Needed',
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        headerBgVariant: 'dark',
                        headerTextVariant: 'white'
                    });
                    if (check) {
                        //Set override flag
                        scheduleItem.registration.vaccineOverride = true;
                    } else {
                        return;
                    }
                } else {
                    //A non-admin cannot override
                    return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                        size: 'md',
                        buttonSize: 'sm'
                    });
                }
            }
            this.registrationRequest = scheduleItem;
            this.registrationRequest.registration.canceled = false;
            this.$bvModal.show(this.confirmRegistrationModalId);
        }
    }

    get schedule() {
        const instructor = this.instructor;
        if (_.isNull(instructor) || _.isEqual(instructor, NULL_USER) || _.isUndefined(instructor.id)) {
            return []
        }
        const schedule = this.$store.getters['instructor/instructorSchedule'](instructor);
        // console.log(schedule);
        return schedule;
    }

    get sessionDetailsModalId() {
        if (_.isNull(this.session)) {
            return 'session-details-modal-invalid';
        }
        else {
            return sprintf('session-details-modal-%d', this.session.sessionId);
        }
    }

    getDetails(session) {
        this.session = session;
        this.$bvModal.show(this.sessionDetailsModalId);
    }

    register() {
        this.$bvModal.show(this.registrationModalId);
    }

    async closeRegistration() {
        this.refresh();
        this.$bvModal.hide(this.registrationModalId);
    }

    showRules() {
        this.$bvModal.show(this.rulesModalId);
    }

    get registrationModalId() {
        if (_.isNull(this.instructor) || _.isEqual(this.instructor, NULL_USER) ||
            _.isNull(this.instructor.id) || _.isNaN(this.instructor.id) || 0 === this.instructor.id) {
            return 'instructor-registration-modal-invalid';
        }
        else {
            return 'instructor-registration-modal-' + this.instructor.id;
        }
    }

    get rulesModalId() {
        if (_.isNull(this.instructor) || _.isEqual(this.instructor, NULL_USER) ||
            _.isNull(this.instructor.id) || _.isNaN(this.instructor.id) || 0 === this.instructor.id) {
            return 'instructor-rules-invalid';
        }
        else {
            return 'instructor-rules-modal-' + this.instructor.id;
        }
    }

    get requestType() {
        return RegistrationRequestType.INSTRUCTOR_SESSION_REGISTRATION;
    }

    get isRegistrable() {
        return this.instructor.status.toUpperCase() === 'ENABLED';
    }

    statusVariant(status) {
        return Status.variant(status);
    }

    isRegistered(status) {
        return _.isEqual(status, Status.REGISTERED);
    }

    isCompleted(status) {
        return _.isEqual(status, Status.COMPLETED);
    }

    isIncomplete(status) {
        return _.isEqual(status, Status.INCOMPLETE);
    }

    isCancelled(status) {
        return _.isEqual(status, Status.CANCELED);
    }

    get allowCompleteIncomplete() {
        //Per Rocio 3/19, no need to implement instructor incomplete/complete registration status
        return false;
    }

    async incomplete(scheduleItem) {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to make this session registration incomplete?', {
                title: 'Confirm Incomplete',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        //Update registration - note that only one of complete/incomplete should have a value
        //Consider implementing workspace
        const oldComplete = scheduleItem.registration.complete;
        const oldIncomplete = scheduleItem.registration.incomplete;
        scheduleItem.registration.complete = null;
        scheduleItem.registration.incomplete = new Date();
        try {
            await this.$store.dispatch('instructor/processRegistration', scheduleItem, {root: true});
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            scheduleItem.registration.complete = oldComplete;
            scheduleItem.registration.incomplete = oldIncomplete;
        }
    }

    async complete(scheduleItem) {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to make this session registration completed?', {
                title: 'Confirm Completed',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        //Update registration - note that only one of complete/incomplete should have a value
        //Consider implementing workspace
        const oldComplete = scheduleItem.registration.complete;
        const oldIncomplete = scheduleItem.registration.incomplete;
        scheduleItem.registration.complete = new Date();
        scheduleItem.registration.incomplete = null;
        try {
            await this.$store.dispatch('instructor/processRegistration', scheduleItem, {root: true});
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            scheduleItem.registration.complete = oldComplete;
            scheduleItem.registration.incomplete = oldIncomplete;
        }
    }

    isCancellable(scheduleItem) {
        if (!!scheduleItem.registration.id && scheduleItem.status === Status.REGISTERED) {
            return scheduleItem.registration.cancelable;
        }
        //Non-registered statuses can't cancel
        else {
            return false;
        }
    }

    async resend(scheduleItem) {
        const registrationId = scheduleItem.registration.id;
        try {
            //If no registration ID is available, handle gracefully
            const success = registrationId ? await registrationDao.sendConfirmation(registrationId) : false;
            if (success) {
                await this.$bvModal.msgBoxOk('Confirmation sent');
            }
            else {
                await this.$bvModal.msgBoxOk('Confirmation could not be sent at this time');
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async cancel(scheduleItem) {
        if (!this.isCancellable(scheduleItem)) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to cancel registration for this session.');
        }
        else {
            const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                'Are you sure you wish to cancel the registration for user %s for session %s? ' +
                'Any related travel requests will be canceled as well.',
                scheduleItem.user.fullname, scheduleItem.session.label), {
                title: 'Confirm Registration Cancellation',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                hideHeaderClose: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
            if (!cancelCheck) {
                return;
            }
            try {
                await this.$store.dispatch('instructor/cancelRegistration', scheduleItem, {root: true});
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
            this.refresh();
        }
    }

    async refresh() {
        try {
            //Sessions required for building schedule
            await this.$store.dispatch('instructor/loadInstructorSessions');
            await this.$store.dispatch('instructor/loadInstructor', this.instructorId);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async mounted() {
        this.refresh();
    }
}

</script>
<style scoped>
    ul.registration-schedule {
        margin: 0;
        padding: 0 0 0 1.25rem;
    }
</style>

<template>
    <div>
        <b-row>
            <b-col>
                <h3>Trainee History for {{pastTrainee.userLabel}}</h3>
                <p>Graduation Date: {{pastTrainee.graduated | dateFormatter('M/d/yyyy')}}</p>
            </b-col>
        </b-row>
        <!-- Program Schedule History -->
        <b-row>
            <b-col>
                <b-table-lite hover striped small
                              stacked="sm"
                              head-variant="dark"
                              table-variant="primary"
                              :fields="programFields"
                              :items="programSchedule">
                    <template v-slot:cell(name)="row">
                        {{row.item.session.programLabel}}
                    </template>
                    <template v-slot:cell(description)="row">
                        {{row.item.session | sessionDescription}}
                    </template>
                    <template v-slot:cell(session)="row">
                        {{row.item.session | sessionDates}}
                    </template>
                    <template v-slot:cell(status)="row">
                        {{row.item.status}}
<!--                        <b-badge-->
<!--                                :variant="statusVariant(row.item.status)"-->
<!--                                :text="row.item.status"-->
<!--                                size="sm" />-->
                    </template>
                </b-table-lite>
            </b-col>
        </b-row>
        <!-- Activity Schedule History -->
        <b-row>
            <b-col>
                <b-table-lite hover striped small
                              stacked="sm"
                              head-variant="dark"
                              table-variant="primary"
                              :fields="activityFields"
                              :items="activitySchedule">
                    <template v-slot:cell(displayId)="row">
                        {{row.item | displayId}}
                        <template v-if="row.item.isCustomActivity() && !row.item.isApproved">
                            <br />
                            <b-badge variant="warning">{{row.item.workspace.status}}</b-badge>
                        </template>
                    </template>
                    <template v-slot:cell(scheduledDate)="row">
                        {{row.item.scheduled | dateFormatter('MMM yyyy')}}
                    </template>
                    <template v-slot:cell(completed)="row">
                        {{ row.item.completed | dateFormatter('M/d/yyyy') }}
                    </template>
                </b-table-lite>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import {Component, Vue, Prop} from 'vue-property-decorator';
import {NULL_USER} from '@/model/user';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import formatters from "@/util/formatters";
import {Status} from "../../../model/registration";
import store from '@/store/store';
import {errorToastOptions, mkDate} from "../../../util/formatters";

@Component({
    components: {},
    filters: {
        dateFormatter: formatters.date,
        sessionDescription: (session) => {
            //Loaded in App
            const programs = store.getters['tracks/getPrograms'];
            const program = _.find(programs, p => {
                return p.idx === session.idx;
            });
            return program.description;
        },
        sessionDates: (session) => {
            const dates = session.dates;
            //Note - for some reason &rarr; isn't rendering properly here
            const formattedDates = _.isNull(dates) ? '' : sprintf('%s to %s',
                formatters.date(dates[0], 'M/d/yyyy'),
                formatters.date(dates[1], 'M/d/yyyy')
            );
            return formattedDates;
        },
        displayId: (activity) => {
            const displayId = activity.isCustomActivity() ? activity.customDisplayId : activity.displayId;
            return displayId;
        },
    },
    asyncComputed: {
        programSchedule: {
            async get() {
                if (_.isEqual(this.pastTrainee, NULL_USER)) {
                    return [];
                }
                try {
                    await store.dispatch('registration/loadTraineeSchedule', this.pastTrainee);
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                const schedule = store.getters['registration/traineeSchedule'](this.pastTrainee.id);
                // console.log(schedule);
                const filteredSchedule = _.filter(schedule, item => {
                    //Exclude scheduled program sessions
                    return !_.isEqual(item.status, Status.SCHEDULED);
                });
                return filteredSchedule;
            }
        },
        activitySchedule: {
            async get() {
                if (_.isEqual(this.pastTrainee, NULL_USER)) {
                    return [];
                }
                try {
                    await store.dispatch('activities/loadTraineeActivitySchedule', this.pastTrainee);
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                const activities = store.getters['activities/traineeActivitySchedule'](this.pastTrainee);
                const filteredActivities = _.chain(activities)
                    .filter(item => {
                        //Don't include cancelled activities
                        return !item.isTraineeActivityCancelled;
                    })
                    .filter(item => {
                        //Don't include incomplete activities
                        return !!item.completed;
                    })
                    .filter(item => {
                        //Don't include declined, invalid, or cancelled custom activities
                        return item.isCustomActivity() ?
                            !item.isDeclined && !item.isInvalid && !item.cancelled :
                            true;
                    })
                    .sortBy(item => {
                        return item.displayIdOrdinal;
                    })
                    .sortBy(item => {
                        return item.displayCategory;
                    })
                    .map(item => {
                        item._rowVariant = item.isLate ? 'info' : null;
                        return item;
                    })
                    .value();
                return filteredActivities;
            }
        }
    }
})

export default class PastTraineeDetails extends Vue {
    @Prop({type: [Number, String]}) mentorId;

    get pastTrainee() {
        //Loaded in parent
        const trainee = this.$store.getters['users/pastTraineeDetails'](this.mentorId);
        return _.isNull(trainee) ? NULL_USER : trainee;
    }

    statusVariant(status) {
        return Status.variant(status);
    }

    get programFields() {
        return [{
            key: 'name',
            label: 'Program'
        }, {
            key: 'description',
            label: 'Description'
        }, {
            key: 'session',
            label: 'Session'
        }, {
            key: 'status',
            label: 'Status'
        }];
    }

    get activityFields() {
        return [{
            key: 'displayId',
            label: 'Activity',
            sortable: true
        }, {
            key: 'title',
            label: 'Title',
            sortable: true
        }, {
            key: 'description',
            label: 'Description',
            sortable: true
        }, {
            key: 'scheduledDate',
            label: 'Scheduled Date',
            tdClass: 'pl-3',
            sortable: true
        }, {
            key: 'completed',
            label: 'Completed Date',
            sortable: true
        }];
    }

    async mounted() {
        console.log(this.pastTrainee.graduated);
        console.log(mkDate(this.pastTrainee.graduated));
        console.log(formatters.date(mkDate(this.pastTrainee.graduated)));
    }
}
</script>


<style>
    a.session {
        color: white;
    }
</style>
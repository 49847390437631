<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col class="p-0">
                    <b-form-group label="Select Trip:">
                        <b-select size="sm" :options="tripOptions" v-model="selectedTrip">
                            <template v-slot:first>
                                <option :value="null"> - </option>
                            </template>
                        </b-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="tripIsSelected">
                <b-col>
                    <b-card no-body class="p-0">
                        <b-card no-body v-if="isTripPending" class="p-0 m-0 border-0">
                            <b-card-header>
                                <strong>Pending Travel</strong>
                            </b-card-header>
                            <b-card-body>
                                Travel for this Registration {{canRequest ? 'has' : 'was' }} not{{ canRequest ? ' been' : '' }} requested.
                                <template v-if="canRequest">
                                    If you would like to request
                                    travel now, please click <b-link :to="{name:'selectedTravel', params: {
                                        attendeeId: selectedTrip.attendeeId,
                                        registrationId: selectedTrip.registrationId
                                    }}">here</b-link>.
                                </template>
                            </b-card-body>
                        </b-card>
                        <b-card no-body v-else-if="isSelectedTripDeclined" class="p-0 m-0 border-0">
                            <b-card-header>
                                <strong>Declined Travel</strong>
                            </b-card-header>
                            <b-card-body>
                                Travel for this Registration was declined.
                                <template v-if="canRequest">
                                    If this was done in error please click <b-link @click="undeclineTravel">here</b-link> to undecline.
                                </template>
                            </b-card-body>
                        </b-card>
                        <b-tabs small card v-model="tabIndex" v-else>
                            <confirmation title="Summary"
                                          :dormException="dormException"
                                          :airfareException="airfareException"
                                          :trip="selectedTrip"/>
                            <traveler-information ref="travelerInformationTab"
                                                  :traveler="(travelerInformationTrip || {}).traveler"
                                                  :disabled="readonly"
                                                  navigable/>
                            <select-dorm-room ref="selectDormRoomTab"
                                              title="Dorm Room"
                                              :trip="dormRoomTrip"
                                              :disabled="readonly"
                                              navigable/>
                            <select-airfare ref="selectAirfareTab"
                                            title="Airfare"
                                            :trip="flightRequestTrip"
                                            :dormStays="dormStays"
                                            :disabled="readonly"
                                            @flight-requests-selected="flightRequestsSelected"
                                            navigable />
                        </b-tabs>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-if="tripIsSelected && !readonly">
                <b-col cols="6" class="mt-4">
                    <b-button size="sm" variant="danger" @click="cancelTrip" v-if="buttonVisible('cancel')">Cancel Trip</b-button>
                </b-col>
                <b-col class="mt-3 text-right">
                    <b-button-group size="sm">
                        <b-button variant="success" @click="saveTrip" v-if="buttonVisible('save')">Save</b-button>
                    </b-button-group>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import {User} from '@/model/user';
import {Trip, validateFlightRequests, FlightRequest} from '@/model/travel';
import travelDao from '@/dao/travel_dao';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import {date, trimToNull} from '@/util/formatters';
import Confirmation from '@/views/private/travel/Confirmation.vue';
import SelectAirfare from '@/views/private/travel/SelectAirfare.vue';
import SelectDormRoom from '@/views/private/travel/SelectDormRoom.vue';
import TravelerInformation from '@/views/private/travel/TravelerInformation.vue';
import {differenceInDays, isAfter} from 'date-fns';
import {errorModalOptions, errorToastOptions} from '@/util/formatters';

@Component({
    components: {
        Confirmation,
        SelectAirfare,
        SelectDormRoom,
        TravelerInformation
    },
    filters: {
        tripTitle: (trip) => {
            const isTrip = trip instanceof Trip;
            return isTrip ? sprintf('%s (%s - %s)',
                trip.workshop,
                date(trip.startDate, 'M/d/yyyy'),
                date(trip.endDate, 'M/d/yyyy')
            ) : '';
        }
    }
})
export default class TravelHistory extends Vue {

    @Prop({type: User}) user;

    confirmationTrip = null;
    travelerInformationTrip = null;
    dormRoomTrip = null;
    flightRequestTrip = null;

    trips = [];

    tabIndex = 0;

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get readonly() {
        const user = this.activeUser;
        const traveler = (this.travelerInformationTrip || {}).traveler || {};
        switch (true) {
            case user.isAnAdministrator():
                return false;
            case user.isAnInstructor():
                return user.id !== traveler.id;
            case user.isAMentor():
                return user.id !== traveler.id && !_.contains(user.traineeIds || [], traveler.id);
            default:
                return true;
        }
    }

    get selectedTrip() {
        return this.confirmationTrip;
    }

    set selectedTrip(trip) {

        const isTrip = trip instanceof Trip;

        this.confirmationTrip = trip;

        // We want each tab to operate on its own
        // representation of this trip so they
        // can be manipulated and saved independently
        // of one another
        this.travelerInformationTrip = isTrip ? trip.clone() : null;
        this.dormRoomTrip = isTrip ? trip.clone() : null;
        this.flightRequestTrip = isTrip ? trip.clone() : null;
        this.$nextTick(() => {
            try {
                this.$refs.selectDormRoomTab.tripChange(this.dormRoomTrip);
            } catch (ignore) {}
        });
    }

    get flightRequests() {
        return (this.flightRequestTrip || {}).flightRequests || {};
    }

    set flightRequests(flightRequests) {
        if (this.flightRequestTrip instanceof Trip) {
            this.flightRequestTrip.flightRequests = flightRequests;
        }
    }

    get tripOptions() {
        return _.chain(this.trips)
            .sortBy(t => t.startDate)
            .map(t => ({text: this.getOptionText(t), value: t}))
            .value();
    }

    get tripIsSelected() {
        return this.selectedTrip instanceof Trip;
    }

    get isTripPending() {
        const tripId = parseInt((this.selectedTrip || {}).id, 10);
        return this.tripIsSelected && !tripId && !this.isSelectedTripDeclined;
    }

    get isSelectedTripDeclined() {
        return true === (this.selectedTrip || {}).declinedTravel;
    }

    get dormStays() {
        return this.selectedTrip instanceof Trip ? this.selectedTrip.dormStays : [];
    }

    get dormException() {
        return this.dormErrors && this.activeUser.isAnAdministrator();
    }

    get dormErrors() {
        return _.any(this.dormStays, (stay) => {
            const startDiff = differenceInDays(this.selectedTrip.startDate, stay.checkIn);
            const endDiff = differenceInDays(stay.checkOut, this.selectedTrip.endDate);

            return 1 < startDiff || 1 < endDiff;
        });
    }

    get airfareException() {
        return this.airfareErrors && this.activeUser.isAnAdministrator();
    }

    get airfareErrors() {
        return !_.isEmpty(validateFlightRequests(this.selectedTrip.flightRequests, this.dormStays, this.selectedTrip));
    }

    get canRequest() {

        const trip = this.selectedTrip || {};

        if (!(trip instanceof Trip) || // Dunno what we're working on, but we're missing the necessary data to determine
            isAfter(new Date(), trip.endDate) || // Cannot request if the session has already passed
            this.activeUser.isATrainee() || // Trainees cannot request travel
            !_.isNaN(parseInt(trip.tripId, 10)) // Cannot request travel, if already requested
        ) {
            return false;
        }

        switch(true) {
            case this.activeUser.isAnAdministrator():
                return true;
            case this.activeUser.isAnInstructor():
                return this.activeUser.id === trip.userId;

            case this.activeUser.isAMentor():
                return this.activeUser.id === trip.userId ||
                    _.contains(this.activeUser.traineeIds, trip.userId);

            default:
                return false;
        }
    }

    buttonVisible(button) {
        const trip = this.selectedTrip || {};
        switch (button) {
            case 'cancel':
                const frs = trip.flightRequests || {};
                return 0 === this.tabIndex && _.every(['to', 'from'], dir =>
                    null === (frs[dir] || {}).cancelable || true === (frs[dir] || {}).cancelable);

            case 'save':

                if (_.isDate(trip.startDate) && isAfter(new Date(), trip.endDate)) {
                    return false;
                }

                switch (this.tabIndex) {
                    case 1:
                    case 2:
                        return true;
                    case 3:
                        return this.$refs.selectAirfareTab.isEditable;
                    default:
                        return false;
                }
        }
    }

    getOptionText(trip) {
        const base = sprintf('%s (%s - %s)',
            trip.workshop,
            date(trip.startDate, 'M/d/yyyy'),
            date(trip.endDate, 'M/d/yyyy')
        );

        const tripId = parseInt(trip.id, 10) || null;

        switch (true) {
            case trip.declinedTravel:
                return `${base} - Travel has been declined`;

            case null == tripId:
                return `${base}${isAfter(new Date(), trip.endDate) ? '' : ' - Pending'}`

            default:
                return base;
        }
    }

    async saveTrip() {

        try {

            switch (this.tabIndex) {
                case 1:
                    return await this.saveTravelerInformation();

                case 2:
                    return await this.saveDormRooms();

                case 3:
                    return await this.saveAirfare();
            }

        } catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async saveTravelerInformation() {

        if (!this.$refs.travelerInformationTab.isValid()) {
            throw new Error('Please correct the highlighted errors before saving.')
        }

        const trip = this.travelerInformationTrip.cloneTemplate();
        trip.traveler = this.travelerInformationTrip.traveler.workspace.cloneTemplate();
        try {
            const savedTrip = await travelDao.saveTrip(trip);
            this.selectedTrip = savedTrip;
            this.saveComplete('Changes have been saved!');
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async saveDormRooms() {
        if (this.$refs.selectDormRoomTab.hasErrors() && !this.activeUser.isAnAdministrator()) {
            throw new Error('The requested Dorm Room selection violates CITF Policy. Please make corrections before saving.');
        }

        const trip = this.dormRoomTrip.cloneTemplate();

        // See note in Travel.vue:acceptTravel()
        trip.dormStays = _.map(this.$refs.selectDormRoomTab.dormStays, (ds) => {
            ds.exception =  this.$refs.selectDormRoomTab.hasErrors() && this.activeUser.isAnAdministrator();
            return ds;
        });
        try {
            const savedTrip = await travelDao.saveTrip(trip);
            this.selectedTrip = savedTrip;
            this.saveComplete('Changes to your Dorm Room selection have been saved.');
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async saveAirfare() {
        if (!this.$refs.selectAirfareTab.isValidSelection && !this.activeUser.isAnAdministrator()) {
            throw new Error('The selected Airfare violates CITF Policy.  Please make corrections before saving.');
        }

        if (!this.$refs.selectAirfareTab.confirm) {
            throw new Error('You must confirm changes to the Full Name before saving.');
        }

        if (!_.isDate(this.$refs.selectAirfareTab.dateOfBirth) || new Date() <= this.$refs.selectAirfareTab.dateOfBirth) {
            throw new Error('You must enter a valid Date of Birth before saving.');
        }

        const trip = this.flightRequestTrip.cloneTemplate();
        trip.traveler = this.flightRequestTrip.traveler.workspace.cloneTemplate();

        trip.flightRequests = _.reduce(['to', 'from'], (frs, direction) => {
            const f = this.$refs.selectAirfareTab[direction + 'Accepted'];
            frs[direction] = f instanceof FlightRequest ? f.cloneTemplate() : null;
            return frs;
        }, {});
        try {
            const savedTrip = await travelDao.saveTrip(trip);
            this.selectedTrip = savedTrip;
            this.saveComplete('Changes to your Flight Requests have been saved.');
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async saveComplete(message, title) {

        const t = trimToNull(title) || 'Saved!';

        return this.$bvModal.msgBoxOk(message, {
            title: t,
            headerBgVariant: 'success',
            headerTextVariant: 'white',
            size: 'sm',
            buttonSize: 'sm'
        });
    }

    async cancelTrip() {
        const sure = await this.$bvModal.msgBoxConfirm('Are you sure you want to cancel this trip?', {
            title: 'Confirm Trip Cancellation',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes, Cancel the Trip',
            cancelVariant: 'info',
            cancelTitle: 'No, Keep the Trip',
            headerBgVariant: 'warning',
            headerTextVariant: 'white'
        });
        if (sure) {
            try {
                const trip = this.selectedTrip.cloneTemplate();
                const idx = _.findIndex(this.trips, (t) => t.id === trip.id);
                await travelDao.cancelTrip(trip);
                await this.saveComplete('This Trip has been canceled.', 'Success!');
                this.trips.splice(idx, 1);
                this.selectedTrip = null;
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }
        //Be reactive! B-E reactive!
        await this.reloadTrips();
    }

    async undeclineTravel() {

        const doUndecline = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to undecline travel for this registration?', {
            title: 'Undecline Travel?',
            headerBgVariant: 'danger',
            headerTextVariant: 'white',
            footerBgVariant: 'dark',
            centered: true,
            okVariant: 'success',
            okTitle: 'Yes',
            cancelVariant: 'danger',
            cancelTitle: 'No',
            buttonSize: 'sm',
            size: 'sm'
        });

        if (!doUndecline) {
            return;
        }

        try {

            await travelDao.undeclineTravel(this.selectedTrip.registrationId);

            this.selectedTrip.declinedTravel = false;

            const navigateToTravel = await this.$bvModal.msgBoxConfirm(
                'Travel requests for this registration are enabled again. Would you like to request travel now?', {
                    title: 'Travel Request Enabled',
                    headerBgVariant: 'success',
                    footerBgVariant: 'dark',
                    centered: true,
                    okVariant: 'success',
                    okTitle: 'Yes',
                    cancelVariant: 'danger',
                    cancelTitle: 'No',
                    buttonSize: 'sm',
                    size: 'sm'
                });

            if (navigateToTravel) {
                this.$router.push({name:'selectedTravel', params: {
                    attendeeId: this.selectedTrip.attendeeId,
                    registrationId: this.selectedTrip.registrationId
                }});
            }

        } catch (err) {
            this.$bvToast.toast(err.message, {
                title: 'Error',
                solid: true,
                variant: 'danger'
            });
        }
    }

    flightRequestsSelected(flightRequests) {
        this.flightRequests = flightRequests;
    }

    async reloadTrips() {
        try {
            this.trips = await travelDao.getTrips(this.user.id);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async mounted() {
        await this.reloadTrips();
    }
}
</script>
<style scoped>

</style>
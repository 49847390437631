<template>
    <b-card class="border-0" no-body bg-variant="light">
        <template v-if="editMode">
            <b-row>
                <b-col :class="showState && required  && isEmpty? 'comments-error' : ''">
                    <rich-text-editor v-model="editComment.comment"/>
                </b-col>
            </b-row>
            <b-row v-if="!justEdit">
                <b-col>
                    <b-link @click="saveEdit">save</b-link> |
                    <b-link @click="cancelEdit">cancel</b-link>
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <template v-if="!justEdit">
                <b-row>
                    <b-col v-html="formattedComment"></b-col>
                </b-row>
                <b-row>
                    <b-col><hr/></b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-link @click="startEdit">edit comments</b-link> |
                        <b-link @click="showHistory">comment history</b-link>
                    </b-col>
                </b-row>
            </template>
        </template>
        <comment-history-modal ref="user-profile-comment-history-modal"
                               :title="'Comments about ' + user.firstName + ' ' + user.lastName"
                               v-if="!justEdit"
                               v-model="comments"/>
    </b-card>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import User from '@/model/user';
import _ from 'underscore';
import RichTextEditor from '@/components/shared/RichTextEditor.vue';
import formatters from '@/util/formatters';
import {NULL_COMMENT} from '@/model/comment';
import CommentHistoryModal from '@/components/shared/CommentHistoryModal.vue';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        RichTextEditor,
        CommentHistoryModal
    },

    filters: {
        lastUpdate: (lastUpdate) => formatters.date(lastUpdate, 'M/d/yyyy h:mm:ss aaaa')
    }
})
export default class UserComments extends Vue {
    @Prop({type: User}) user;
    @Prop({type: Boolean, default: false}) justEdit;
    @Prop({type: Boolean, default: false}) showState;
    @Prop({type: Boolean, default: false}) required;

    em = false;

    userComments = [];

    editComment = NULL_COMMENT.clone();

    get editMode() {
        return this.justEdit || this.em;
    }

    set editMode(em) {
        this.em = em;
    }

    get comments() {
        return _.sortBy(this.userComments, (c) => c.lastUpdated).reverse();
    }

    get isEmpty() {
        return _.isEmpty(this.editComment.comment);
    }

    get comment() {
        return _.chain(this.comments)
            .sortBy((c) => c.lastUpdated)
            .reverse()
            .first()
            .value() || NULL_COMMENT.clone();
    }

    startEdit() {
        this.editComment.copyFrom(this.comment.cloneTemplate());
        this.editComment.recordId = this.user.id;
        this.editMode = true;
    }

    showHistory() {
        this.$refs['user-profile-comment-history-modal'].show();
    }

    cancelEdit() {
        this.editMode = false;
    }

    get formattedComment() {
        const comment = ((this.comment || {}).comment || '').trim();
        return _.isEmpty(comment) ? 'No Comments' : comment;
    }

    get historyFields() {
        return [{
            key: 'comment',
            label: 'Comment'
        }, {
            key: 'updatedBy',
            label: 'Username'
        }, {
            key: 'lastUpdated',
            label: 'Updated'
        }];
    }

    hasError() {
        return this.required && this.isEmpty;
    }

    async saveEdit() {
        try {
            const savedComment = await this.$store.dispatch('users/saveComments', this.editComment);
            this.userComments.push(savedComment);
            this.editMode = false;
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.editComment = NULL_COMMENT.clone();
        this.refresh();
    }

    async refresh() {
        try {
            this.userComments = await this.$store.dispatch('users/getComments', this.user.id);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}

</script>
<style scoped>

    div.comments-error > div {
        border: 1px solid #F04124 !important;
    }


</style>
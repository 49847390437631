<template>
    <b-row class="m-2">
        <b-col>
            <b-row>
                <b-col>
                    <strong>{{trainee.fullname}} ({{trainee.ubcId}})</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <template v-if="!expanded">
                        <b-link v-b-toggle="'trainee-schedule-'+trainee.id">registrations</b-link> |
                    </template>
                    <template v-if="!onOwnProfile">
                        <b-link :to="{name:'profileView', params: {id: trainee.id, activeTabIndex: 0}}">profile</b-link> |
                    </template>
                    <b-link @click="goToTravel">travel</b-link> |
                    <b-link :to="{name:'schedule', params: {userId: trainee.id}}">schedule</b-link> |
                    <b-link @click="getTranscript(trainee.id)">transcript</b-link>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-collapse :id="'trainee-schedule-'+trainee.id" :visible="expanded">
                        <registrations-snapshot :user="trainee" />
                    </b-collapse>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import {Trainee, User, NULL_USER} from '@/model/user';
import RegistrationsSnapshot from '@/views/private/users/snippet/RegistrationsSnapshot.vue';
import download from '@/util/downloader';
import {sprintf} from 'sprintf-js';
import {errorModalOptions} from "../../../../util/formatters";

@Component({
    components: {
        RegistrationsSnapshot
    }
})

export default class TraineeProgramSnapshot extends Vue {
    @Prop({type: [Trainee, User], default: NULL_USER}) trainee;
    @Prop({type: Boolean, default: false}) expanded;

    goToTravel() {
        if (this.onOwnProfile) {
            this.$emit('updateTab', 2);
        }
        else {
            this.$router.push({name:'profileView', params: {id: this.trainee.id, activeTabIndex: 2}});
        }
    }

    async getTranscript(traineeUserId) {
        try {
            await download('POST', '/api/v1/report/trainee-transcript', JSON.stringify({
                userProfileId: traineeUserId
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    get onOwnProfile() {
        const routeId = +this.$route.params.id;
        const traineeId = this.trainee.id;
        return routeId === traineeId;
    }
}
</script>
<style scoped>

</style>
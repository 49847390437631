<template>
    <b-row class="col-md-12">
        <b-table-lite small borderless striped hover stacked outlined :fields="tableFields" :items="tableData">
            <template v-slot:cell(securityLevel)="row">
                <b-form-group class="profile-field"
                              :state="errorState('securityLevel')"
                              invalid-feedback="Security Level is required."
                              v-if="row.item.editMode">
                    <b-select size="sm"
                              @change="roleChange"
                              v-model="securityLevel"
                              :state="errorState('securityLevel')"
                              :options="securityLevelOptions"
                              :disabled="!canEditSecurityLevel"/>
                </b-form-group>
                <template v-else>{{row.item.securityLevel}}</template>
            </template>
            <template v-slot:cell(username)="row">
                <b-form-group class="profile-field"
                              :state="isUsernameValid"
                              :invalid-feedback="invalidUsernameFeedback"
                              v-if="row.item.editMode && activeUser.isAnAdministrator()">
                    <b-input size="sm" v-model="uname" :state="isUsernameValid" placeholder="Username"/>
                </b-form-group>
                <template v-else>{{row.item.username}}</template>
            </template>
            <template v-slot:cell(ubcId)="row">
                <b-form-group class="profile-field"
                              v-if="row.item.editMode && ubcIdIsEditable && canEditPersonalInfo"
                              :state="errorState('ubcId')"
                              invalid-feedback="UBC ID validation is required.">
                    <b-input-group>
                        <ubc-id input-class="form-control form-control-sm"
                                @input="ubcIdChanged"
                                v-model="row.item.workspace.ubcId"
                                :show-status="ubcIdValidationNeeded" />
                        <b-input-group-addon v-if="ubcIdIsEditable">
                            <b-button size="sm" variant="primary" @click="lookupUbcId">Lookup</b-button>
                        </b-input-group-addon>
                    </b-input-group>
                </b-form-group>
                <template v-else>{{ row.item.ubcId | ubcId }}</template>
                <span v-if="ubcIdValidationNeeded && !validatedUbcId" class="ubc-id-error"><br />User edits are invalid.</span>
            </template>
            <template v-slot:cell(name)="row">
                <template v-if="row.item.editMode && ubcIdIsEditable && canEditPersonalInfo">
                    <b-row>
                        <b-col cols="4">
                            <b-form-group class="profile-field" :state="errorState('firstName')" invalid-feedback="First Name is required.">
                                <b-input size="sm"
                                         placeholder="First Name"
                                         v-model="row.item.workspace.firstName"
                                         :disabled="!ubcIdIsEditable"
                                         :state="errorState('firstName')"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-input size="sm" placeholder="Middle Name" v-model="row.item.workspace.middleName" :disabled="!ubcIdIsEditable"/>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group class="profile-field" :state="errorState('lastName')" invalid-feedback="Last Name is required.">
                                <b-input size="sm"
                                         placeholder="Last Name"
                                         v-model="row.item.workspace.lastName"
                                         :disabled="!ubcIdIsEditable"
                                         :state="errorState('lastName')"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </template>
                <template v-else>{{row.item | name}}</template>
            </template>
            <template v-slot:cell(suffix)="row">
                <b-input size="sm" placeholder="Suffix" v-if="row.item.editMode && ubcIdIsEditable && canEditPersonalInfo" v-model="row.item.workspace.suffix" :disabled="!ubcIdIsEditable"/>
                <template v-else>{{row.item.suffix}}</template>
            </template>
            <template v-slot:cell(localUnion)="row">{{row.item.local}}</template>
            <template v-slot:cell(title)="row">
                <b-form-group v-if="row.item.editMode && canEditPersonalInfo" class="profile-field" :state="errorState('title')" invalid-feedback="Title is required.">
                    <b-input size="sm" placeholder="Title" v-model="row.item.workspace.title" :state="errorState('title')"/>
                </b-form-group>
                <template v-else>{{row.item.title}}</template>
            </template>
            <template v-slot:cell(email)="row">
                <b-form-group v-if="row.item.editMode && canEditContactInfo" class="profile-field" :state="errorState('email')" invalid-feedback="A valid email address is required.">
                    <b-input size="sm" placeholder="Email Address" v-model="row.item.workspace.email" :state="errorState('email')"/>
                </b-form-group>
                <template v-else>{{row.item.email}}</template>
            </template>
            <template v-slot:cell(phone)="row">
                <b-form-group v-if="row.item.editMode && canEditContactInfo" class="profile-field" :state="errorState('phone')" invalid-feedback="A Mobile Phone Number is required.">
                    <phone-number input-class="form-control form-control-sm" v-model="phoneNumber" show-status/>
                </b-form-group>
                <template v-else>{{row.item.phone.formatted}}</template>
            </template>
            <template v-slot:cell(emergencyContact)="row">
                <template v-if="row.item.editMode && canEditContactInfo">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group class="profile-field" :state="errorState('emergencyContactName')" invalid-feedback="Emergency Contact Name is required.">
                                <b-input size="sm"
                                         placeholder="Emergency Contact"
                                         v-model="row.item.workspace.emergencyContact.name"
                                         :state="errorState('emergencyContactName')"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group class="profile-field"
                                          :state="errorState('emergencyContactRelationship')"
                                          invalid-feedback="Emergency Contact Relationship is required.">
                                <b-input size="sm"
                                         placeholder="Relationship"
                                         v-model="row.item.workspace.emergencyContact.relationship"
                                         :state="errorState('emergencyContactRelationship')"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col cols="6" class="clearfix"><div class="float-right">Day Phone:</div></b-col>
                        <b-col cols="6">
                            <b-form-group class="profile-field"
                                          :state="errorState('emergencyContactDayPhone')"
                                          invalid-feedback="Emergency Contact Day Phone is required.">
                                <phone-number input-class="form-control form-control-sm"
                                              v-model="row.item.workspace.emergencyContact.dayPhone.formatted"
                                              :state="errorState('emergencyContactDayPhone')"
                                              show-status/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col cols="6" class="clearfix"><div class="float-right">Night Phone:</div></b-col>
                        <b-col cols="6">
                            <b-form-group class="profile-field"
                                          :state="errorState('emergencyContactNightPhone')"
                                          invalid-feedback="Emergency Contact Night Phone is required.">
                                <phone-number input-class="form-control form-control-sm"
                                              v-model="row.item.workspace.emergencyContact.nightPhone.formatted"
                                              :state="errorState('emergencyContactNightPhone')"
                                              show-status/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </template>
                <template v-else>
                    <div v-html="emergencyContact"></div>
                </template>
            </template>
            <template v-slot:cell(organization)="row">
                <b-form-group v-if="row.item.editMode && canEditPersonalInfo"
                              class="profile-field"
                              :state="errorState('organization')"
                              :invalid-feedback="'A' + (selectedUser.workspace.isAMentor() || selectedUser.workspace.isATrainee() ? ' Contractor' : 'n Organization') + ' is required.'">
                    <b-select size="sm" :options="organizationSelectOptions" v-model="userWorkspaceOrganization" :state="errorState('organization')">
                        <template slot="first">
                            <option :value="null" disabled> - </option>
                        </template>
                    </b-select>
                </b-form-group>
                <template v-else>
                    <template v-if="hasOrg">
                        {{row.item.organization.name}}
                        <b-badge :variant="userOrganization.participating ? 'success' : 'danger'" v-if="selectedUser.isAMentor() || selectedUser.isATrainee()">
                            <template v-if="!userOrganization.participating">Not</template> Participating
                        </b-badge>
                    </template>
                </template>
            </template>
            <template v-slot:cell(mentor)="row">
                <b-form-group class="profile-field"
                              :state="errorState('mentor')"
                              invalid-feedback="Mentor is required."
                              v-if="activeUser.isAnAdministrator() && row.item.editMode">
                    <b-select size="sm"
                              :options="mentorOptions"
                              :state="errorState('mentor')"
                              v-model="traineeMentor">
                        <template v-slot:first>
                            <option :value="null"> - </option>
                        </template>
                    </b-select>
                </b-form-group>
                <template v-else>
                    <template v-if="showMentor">
                        <b-link :to="{name:'profileView', params: {id: mentor.id, activeTabIndex: 0}}">
                            {{ mentor.firstName + ' ' + mentor.lastName + ' (' + mentor.username + ')'}}
                        </b-link>
                        <template v-if="!!mentorOrganization">
                            - {{mentorOrganization}}
                        </template>
                    </template>
                </template>
            </template>
            <template v-slot:cell(traineeStatus)="row">
                <template v-if="selectedUser.isATrainee() || selectedUser.workspace.isATrainee()">
                    <template v-if="activeUser.isAnAdministrator() && row.item.editMode">
                        <b-form-group class="profile-field"
                                      :state="errorState('traineeStatus')"
                                      invalid-feedback="Trainee Status is required.">
                            <b-select size="sm"
                                      :options="traineeStatuses"
                                      v-model="traineeStatus"
                                      :disabled="!editStatus"
                                      :state="errorState('traineeStatus')">
                                <template v-slot:first>
                                    <option :value="null" disabled> - </option>
                                </template>
                            </b-select>
                        </b-form-group>
                        <b-form-group class="profile-field" v-if="showTraineeStatusChangeReason"
                                      :state="errorState('traineeStatusChangeReason')"
                                      invalid-feedback="A reason for this change is required.">
                            <b-select size="sm"
                                      :options="traineeStatusChangeReasonOptions"
                                      :state="errorState('traineeStatusChangeReason')"
                                      v-model="selectedUser.workspace.traineeStatusChangeReason">
                                <template v-slot:first>
                                    <option :value="null" disabled> Please select a reason for this change. </option>
                                </template>
                            </b-select>
                        </b-form-group>
                        <b-form-group v-if="commentRequired"
                                      invalid-feedback="Comments are required."
                                      :state="errorState('traineeStatusComments')">
                            <template v-slot:label><strong>Status Change Comments</strong></template>
                            <user-comments ref="trainee-status-change-comments"
                                           :user="selectedUser"
                                           show-state
                                           required
                                           :just-edit="true"/>
                        </b-form-group>
                    </template>
                    <template v-else>{{(row.item.traineeStatus || {}).status || ''}}</template>
                </template>
            </template>
            <template v-slot:cell(track)="row">
                <b-form-group class="profile-field"
                              v-if="activeUser.isAnAdministrator() && row.item.editMode"
                              :state="errorState('track')"
                              :invalid-feedback="trackError">
                    <b-select size="sm"
                              :options="trackOptions"
                              :state="errorState('track')"
                              v-model="traineeTrackId">
                        <template v-slot:first>
                            <option :value="null"> - </option>
                        </template>
                    </b-select>
                </b-form-group>
                <template v-else>
                    {{ (traineeTrack || {}).title }}
                </template>
            </template>
            <template v-slot:cell(mentorStatus)="row">
                {{ row.item.active ? 'Active' : 'Inactive'}}
            </template>
            <template v-slot:cell(vaccineVerified)="row">
                <b-form-group class="profile-field"
                              v-if="canVerifyVaccine"
                              :state="errorState('vaccineVerified')">
                    <template #invalid-feedback>
                        <p>You cannot register a participant unless they are vaccinated for COVID-19.</p>
                        <p>
                            UBC Members: Must contact their Local Training Center to verify their vaccine card
                            and add the COVID-19 Vaccine Verified Qual/Cert in the TRAIN system.
                        </p>
                        <p>Non-UBC Members: Please contact CITF Administration.</p>
                    </template>
                    <b-checkbox switch
                                size="lg"
                                v-model="vaccineVerified"
                                :value="true"
                                :unchecked-value="false"
                                :state="errorState('vaccineVerified')"
                                @change="confirmVaccineVerified">
                        {{row.item.workspace.vaccineVerified ? 'Verified' : 'Not Verified'}}
                    </b-checkbox>
                </b-form-group>
                <b-form-group v-else>
                    <b-badge v-b-tooltip.hover title="Please contact CITF Administration if you need to make changes to this field."
                             :variant="!!row.item.vaccineVerified ? 'success' : 'danger'">
                        {{row.item.workspace.vaccineVerified ? 'Verified' : 'Not Verified'}}
                    </b-badge>
                </b-form-group>
                {{row.item.workspace | vaccineDetails}}
            </template>
            <template v-slot:cell(status)="row">
                <b-checkbox v-if="canEditSecurityLevel"
                            switch
                            size="lg"
                            :disabled="!editStatus"
                            v-model="userStatus"
                            :value="enabledStatus"
                            :unchecked-value="disabledStatus">
                    {{ row.item.workspace.status }}
                </b-checkbox>
                <b-badge v-else
                         :variant="row.item.status == 'Enabled' ? 'success' : 'danger'">
                    {{row.item.status}}
                </b-badge>
            </template>
            <template v-slot:cell(comments)="row">
                <user-comments ref="comments-for-user"
                               :key="selectedUserKey"
                               :user="selectedUser"
                               v-if="showComments"/>
            </template>
        </b-table-lite>
        <template v-if="canEdit">
            <b-button-group size="sm" v-if="selectedUser.editMode">
                <b-button variant="info" @click="saveUser">Save</b-button>
                <b-button variant="primary" class="ml-2" @click="cancelEditing">Cancel</b-button>
            </b-button-group>
            <b-button-group size="sm" v-else>
                <b-button variant="warning"
                          @click="startEditing">Edit {{ selectedUser.firstName }} {{ selectedUser.lastName }}</b-button>
                <b-button variant="primary"
                          class="ml-2"
                          @click="resetPassword">Reset Password</b-button>
                <b-button variant="success"
                          v-if="hasPastTraineeDetails"
                          class="ml-2"
                          @click="viewPastTraineeDetails">Past Trainee Details</b-button>
            </b-button-group>
        </template>
        <!-- Past trainee details -->
        <b-modal :id="pastTraineeDetailsModalId"
                 size="xl"
                 scrollable
                 title="Past Trainee Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <PastTraineeDetails :mentorId="selectedUser.id" />
        </b-modal>
    </b-row>
</template>
<script>

import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {User, NULL_USER, NULL_TRAINEE} from '@/model/user';
import {sprintf} from 'sprintf-js';
import { format } from 'date-fns';
import {NULL_ORGANIZATION} from '@/model/organization';
import {RoleOptions} from '@/model/security_level';
import {Phone} from '@/model/phone';
import getFields from './profile_fields';
import _ from 'underscore';
import PhoneNumber from '@/components/shared/PhoneNumber.vue';
import {UbcId, UBCID_REGEX, formatUbcId} from '@/components/shared/UbcId';
import userDao from '@/dao/user_dao';
import userSessionDao from '@/dao/user_session_dao';
import {NULL_EMERGENCY_CONTACT} from '@/model/emergency_contact';
import {isBefore} from 'date-fns';
import memberDao from '@/dao/member_dao';
import {NULL_MEMBER} from '@/model/member';
import {SecurityLevel} from '@/model/security_level';
import UserComments from '@/views/private/users/UserComments.vue';
import {Track} from '@/model/track';
import RichTextEditor from '@/components/shared/RichTextEditor.vue';
import PastTraineeDetails from "./PastTraineeDetails";
import {errorModalOptions, errorToastOptions, mkDate} from "../../../util/formatters";

@Component({
    components: {
        PastTraineeDetails,
        PhoneNumber,
        UbcId,
        UserComments,
        RichTextEditor
    },
    filters: {
        name: (user) => {
            return sprintf('%s%s %s',
                user.firstName,
                _.isEmpty((user.middleName || '').trim()) ? '' : ' ' + user.middleName,
                user.lastName
            );
        },
        ubcId: (ubcId) => {
            return formatUbcId(ubcId);
        },
        vaccineDetails: (user) => {
            if (!user.vaccineVerified) {
                return '';
            }
            else {
                const date = format(mkDate(user.vaccineVerifiedDate), 'M/d/yyyy') || '';
                const username = sprintf(' (%s)', user.vaccineVerifiedByUsername) || '';
                return sprintf('%s%s', date, username);
            }
        }
    },
    asyncComputed: {
        async isUsernameValid() {
            if (_.isEmpty(this.uname)) {
                return false;
            }

            if (this.uname === this.selectedUser.username) {
                return true;
            }

            try {
                return await userDao.validUsername(this.uname);
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }
    }
})
export default class ProfileTable extends Vue {
    @Prop({type: User}) user;
    validatedUbcId = false;
    editStatus = true;
    pnum = null;
    tableFields = [];
    uname = '';

    async confirmVaccineVerified() {
        //Fires before variable updated, and make sure it's a status change...
        if (!this.vaccineVerified && !this.selectedUser.vaccineVerified) {
            const check = await this.$bvModal.msgBoxConfirm(
                'Are you sure the participant\'s COVID-19 vaccine has been verified?\n\n' +
                'Click OK to confirm. ' +
                '(This action cannot be undone. You will need to contact CITF Administration for changes.)\n\n' +
                'Click cancel if you are unable to confirm at this time.', {
                    title: 'Confirm Vaccine Verification',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
            if (check) {
                //Auto save - add conditional checks/disable here
                this.saveUser();
            } else {
                //Reset value
                this.vaccineVerified = false;
            }
        }
    }

    get canVerifyVaccine() {
        return this.selectedUser.editMode && this.activeUser.isAnAdministrator();
    }

    get vaccineVerified() {
        return this.selectedUser.workspace.vaccineVerified || false;
    }

    set vaccineVerified(value) {
        this.selectedUser.workspace.vaccineVerified = value;
        //Reset or update verified by/verified date if new value matches original or is new
        if (this.selectedUser.workspace.vaccineVerified === this.selectedUser.vaccineVerified) {
            this.selectedUser.workspace.vaccineVerifiedByUsername = this.selectedUser.vaccineVerifiedByUsername;
            this.selectedUser.workspace.vaccineVerifiedDate = this.selectedUser.vaccineVerifiedDate;
        } else {
            this.selectedUser.workspace.vaccineVerifiedByUserId = this.activeUser.id;
            this.selectedUser.workspace.vaccineVerifiedByUsername = sprintf('SCT - %s', this.activeUser.username.toUpperCase());
            this.selectedUser.workspace.vaccineVerifiedDate = new Date();
        }
    }

    get pastTraineeDetailsModalId() {
        const id = sprintf('past-trainee-deets-modal-%s', !!this.selectedUser.id ? this.selectedUser.id : 'invalid');
        return id;
    }

    viewPastTraineeDetails() {
        this.$bvModal.show(this.pastTraineeDetailsModalId);
    }

    get hasPastTraineeDetails() {
        if (!this.selectedUser.isAMentor()) {
            return false;
        }
        else {
            const traineeDetails = this.$store.getters['users/pastTraineeDetails'](this.selectedUser.id);
            // console.log(traineeDetails);
            return !_.isNull(traineeDetails);
        }
    }

    async roleChange() {
        const oldRole = this.selectedUser.securityLevel;
        const newRole = this.selectedUser.workspace.securityLevel;
        if (_.isEqual(oldRole, newRole)) {
            //If role is reverted and UBC ID hasn't changed, we can assume UBC ID is valid
            const oldUbcId = formatUbcId(this.selectedUser.workspace.ubcId);
            const newUbcId = formatUbcId(this.selectedUser.ubcId);
            if (_.isEqual(newUbcId, oldUbcId)) {
                this.validatedUbcId = true;
            }
            //If role is reverted and user is not new, we can allow for status change, but revert status first
            this.selectedUser.workspace.status = this.selectedUser.status;
            this.selectedUser.workspace.statusId = this.selectedUser.statusId;
            this.selectedUser.workspace.traineeStatus = this.selectedUser.traineeStatus;
            this.editStatus = !this.selectedUser.workspace.isNew;
        }
        else {
            //If role has changed, status should be set and locked
            this.editStatus = false;
            this.userStatus = this.enabledStatus;
            //If role has changed to trainee, trainee status should be set and locked and UBC ID validation is required
            if (_.isEqual(newRole, SecurityLevel.TRAINEE)) {
                const enabledTraineeStatus = _.find(this.availableTraineeStatuses, (s) => {
                    return _.isEqual(s.status.toUpperCase(), 'ENROLLED');
                });
                this.selectedUser.workspace.traineeStatus = enabledTraineeStatus;
                await this.lookupUbcId();
            }
        }
        // console.log(this.selectedUser.workspace);
    }

    get trackError() {
        return this.isNewTrainee ? 'Program 1 Session is required.' : 'Track is required.';
    }

    get isNewTrainee() {
        return this.selectedUser.workspace.isNew && this.selectedUser.workspace.isATrainee();
    }

    ubcIdChanged() {
        //If UBC ID entry changes, input must be always be validated (pre-validated UBC IDs aren't editable)
        this.validatedUbcId = false;
    }

    get ubcIdValidationNeeded() {
        return (this.selectedUser.workspace.isATrainee() || !!this.selectedUser.workspace.ubcId) && !this.validatedUbcId;
    }

    //Logged in user
    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    //Display user
    get selectedUser() {
        return this.user instanceof User ? this.user : NULL_TRAINEE.clone();
    }

    //Use component key so UserComments are reactive for status changes as well as explicit edits
    get selectedUserKey() {
        return this.selectedUser.statusId || 0;
    }

    //Can logged in user edit anything for display user?
    get canEdit() {
        const activeUser = this.activeUser;
        const selectedUser = this.selectedUser;
        switch (true) {
            case activeUser.isAnAdministrator():
                return true;
            case activeUser.isAMentor():
                return activeUser.id === selectedUser.id || _.contains(activeUser.traineeIds, selectedUser.id);
            default:
                return activeUser.id === selectedUser.id;
        }
    }

    //Can logged in user edit security level for display user?
    get canEditSecurityLevel() {
        const activeUser = this.activeUser;
        return this.selectedUser.editMode && activeUser.isAnAdministrator() && !activeUser.equals(this.selectedUser);
    }

    //Can logged in user edit contact info (email, phone, emergency contact) for display user?
    get canEditContactInfo() {
        //All users can edit contact info for a user they can edit
        return this.canEdit;
    }

    //Can logged in user edit personal info (UBC ID, name, organization, title, address) for display user?
    get canEditPersonalInfo() {
        //Only admins can edit personal info for a user they can edit
        return this.canEdit && this.activeUser.isAnAdministrator();
    }

    get invalidUsernameFeedback() {
        const uname = (this.selectedUser.workspace.username || '').trim();
        return _.isEmpty(uname) ? 'Username is required.' : 'The provided Username is either invalid or is already in use.';
    }

    get ubcIdIsEditable() {
        const editable = !UBCID_REGEX.test(this.selectedUser.ubcId);
        return editable;
    }

    get phoneNumber() {
        return this.pnum;
    }

    set phoneNumber(pnum) {
        this.pnum = pnum;
    }

    get nullOrganization() {
        return NULL_ORGANIZATION;
    }

    get securityLevelOptions() {
        return RoleOptions;
    }

    get securityLevel() {
        return this.selectedUser.workspace.securityLevel;
    }

    set securityLevel(level) {
        this.selectedUser.workspace.securityLevel = level;
        this.tableFields = [];
        const activeUser = this.activeUser;
        const fields = getFields(level);
        if (!activeUser.isAnAdministrator()) {
            const idx = _.findIndex(fields, (f) => f.key === 'comments');
            fields.splice(idx, 1);
        }
        if (this.isNewTrainee) {
            const idx = _.findIndex(fields, (f) => f.key === 'track');
            fields[idx]['label'] = 'Program 1 Session:';
        }
        this.tableFields = fields;
    }

    get availableTraineeStatuses() {
        return this.$store.getters['trainees/statuses'];
    }

    get traineeStatuses() {
        return _.map(this.availableTraineeStatuses, (status) => {
            return {
                value: status.id,
                text: status.status
            };
        });
    }

    get traineeStatus() {
        const traineeStatus = this.selectedUser.workspace.traineeStatus || null;
        if (_.isNull(traineeStatus)) {
            //Default status to enrolled
            const status = _.find(this.availableTraineeStatuses, (s) => s.status === 'Enrolled');
            if (_.isUndefined(status)) {
                return null;
            }
            this.selectedUser.workspace.traineeStatus = status;
            return status.id;
        }
        return traineeStatus.id;
    }

    set traineeStatus(statusId) {
        this.selectedUser.workspace.traineeStatusChangeReason = null;
        const status = this.$store.getters['trainees/statusById'](statusId);
        this.selectedUser.workspace.traineeStatus = status;
    }

    get showTraineeStatusChangeReason() {
        // Only show the change status reason
        // If
        // A) Selected user is a trainee
        // B) The status being changed to is not Enrolled
        // C) The status being changed to is different than the current status
        // D) The status being changed to is 'On Hold' or 'Dropped'
        return this.selectedUser.workspace.isATrainee() && !this.selectedUser.workspace.isNew &&
            'Enrolled' !== this.selectedUser.workspace.traineeStatus.status &&
            this.selectedUser.traineeStatus.id !== this.selectedUser.workspace.traineeStatus.id &&
            _.contains(['On Hold', 'Dropped'], this.selectedUser.workspace.traineeStatus.status);
    }

    get traineeStatusChangeReasonOptions() {
        let reasons = [];
        if (!this.selectedUser.isATrainee()) {
            return reasons;
        }
        switch (this.selectedUser.workspace.traineeStatus.status) {
            case 'On Hold':
                reasons = [
                    'Injury/Illness',
                    'Personal',
                    'Needs New Mentor'
                ];
                break;
            case 'Dropped':
                reasons = [
                    'No Longer Works for Contractor',
                    'Canceled Out of Classes Too Many Times',
                    'Violated CITF Policy – Banned from ITC'
                ];
                break;
            default:
                return reasons;
        }
        reasons.push('Other');
        return reasons;
    }

    //TODO - deprecate?
    getTraineeStatusChangeComment() {
        const key = 'trainee-status-change-comments';
        return _.has(this.$refs, key) ? this.$refs[key].editComment || {} : {};
    }

    get enabledStatus() {
        return _.find(this.userStatuses, (s) => s.status === 'Enabled');
    }

    get disabledStatus() {
        return _.find(this.userStatuses, (s) => s.status === 'Disabled');
    }

    get userStatus() {
        return _.find(this.userStatuses, (s) => s.id === this.selectedUser.workspace.statusId);
    }

    set userStatus(status) {
        this.selectedUser.workspace.statusId = status.id;
        this.selectedUser.workspace.status = status.status;
    }

    get userStatuses() {
        return this.$store.getters['users/statuses'];
    }

    get needsContractor() {
        return this.selectedUser.workspace.isAMentor() || this.selectedUser.workspace.isATrainee();
    }

    get availableOrganizations() {
        return _.chain(this.$store.getters['organizations/getOrganizations'])
            .filter((o) => {
                if (!this.needsContractor) {
                    return true;
                }
                else {
                    //Assigned or contractor/participating organization
                    return o.equals(this.selectedUser.organization) || (!!o.type && o.participating);
                }
            })
            .sortBy((o) => o.name)
            .value();
    }

    get organizationSelectOptions() {
        return _.map(this.availableOrganizations, (o) => {
            return {
                value: o.id,
                text: sprintf('%s%s',
                    o.name,
                    !o.type ? '' : sprintf(' (%sParticipating)', o.participating ? '' : 'Not ')
                )
            };
        });
    }

    get userWorkspaceOrganization() {
        return parseInt(this.selectedUser.workspace.organization.id, 10) || null;
    }

    set userWorkspaceOrganization(orgId) {
        const org = _.find(this.availableOrganizations, (o) => o.id === orgId);
        this.selectedUser.workspace.organization = org || NULL_ORGANIZATION;
    }

    get userOrganization() {
        const org = _.find(this.availableOrganizations, (o) => o.id === this.selectedUser.organization.id);
        return org || NULL_ORGANIZATION;
    }

    get hasOrg() {
        const org = this.userOrganization;
        return !!org && !NULL_ORGANIZATION.equals(org);
    }

    get showMentor() {
        return !NULL_USER.equals(this.mentor);
    }

    get mentor() {
        if (!this.selectedUser.isATrainee()) {
            return NULL_USER;
        }
        const mentor = this.$store.getters['mentors/mentor'];
        return !!mentor ? mentor : NULL_USER;
    }

    get mentorOrganization() {
        return ((this.mentor.organization || {}).name || '');
    }

    get emergencyContact() {
        const em = this.selectedUser.emergencyContact;
        return NULL_EMERGENCY_CONTACT.equals(em) ? '' :
            sprintf('%s, %s<br/>Day: %s<br/>Night: %s',
                em.name,
                em.relationship,
                em.dayPhone.formatted,
                em.nightPhone.formatted
            );
    }

    get allMentors() {
        return this.$store.getters['mentors/mentorsAsUsers'];
    }

    get availableMentors() {
        const trainee = this.selectedUser;
        return _.chain(this.allMentors)
            .filter((m) => trainee.mentorId === m.id || 'Enabled' === m.status)
            .sortBy((m) => m.firstName)
            .sortBy((m) => m.lastName)
            .value();
    }

    get mentorOptions() {
        if (!this.activeUser.isAnAdministrator()) {
            return [];
        }
        return _.map(this.availableMentors, (m) => {
                const text = sprintf('%s, %s%s',
                    m.lastName,
                    m.firstName,
                    !!m.organization && !NULL_ORGANIZATION.equals(m.organization) ? ' - ' + m.organization.name : ''
                );
                return {
                    value: m.id,
                    text
                };
            });
    }

    get traineeMentor() {
        const mentor = _.find(this.allMentors, (m) => m.id === this.selectedUser.workspace.mentorId);
        return !!mentor ? mentor.id : null;
    }

    set traineeMentor(mentorId) {
        this.selectedUser.workspace.mentorId = mentorId;
    }

    get availableTracks() {
        return _.chain(this.$store.getters['tracks/getTracks'])
            .filter((t) => {
                const now = new Date();
                const dateToCompare = this.isNewTrainee ? t.program1.dates[0] : t.program4.dates[0];
                //Track must be assigned to selected user OR not canceled/past
                return t.id === this.selectedUser.trackId ||
                    (!t.canceled && isBefore(now, dateToCompare));
            })
            .sortBy((t) => t.program1.dates[0])
            .value();
    }

    get trackOptions() {
        //Offer program 1 sessions to new trainees and tracks to all others
        if (this.isNewTrainee) {
            const program1s = _.map(this.availableTracks, (t) => {
                const p1 = t.program1;
                const programTitle = sprintf('%s (%s)', p1.label, p1.availableSeatsOnlyLabel);
                return {
                    value: t.id,
                    text: programTitle
                };
            });
            return program1s;
        }
        else {
            const tracks = _.map(this.availableTracks, (t) => {
                const trackTitle = t.canceled ? sprintf('%s (canceled)', t.title) : t.title;
                return {
                    value: t.id,
                    text: trackTitle
                };
            });
            return tracks;
        }
    }

    get traineeTrack() {
        return _.find(this.availableTracks, (t) => t.id === this.selectedUser.trackId) || {};
    }

    get traineeTrackId() {
        return this.selectedUser.workspace.trackId;
    }

    set traineeTrackId(trackId) {
        this.selectedUser.workspace.trackId = trackId;
    }

    get showComments() {
        return this.activeUser.isAnAdministrator() && !this.selectedUser.isNew;
    }

    get tableData() {
        const user = this.selectedUser;
        const data = [user];
        if (user.isATrainee()) {
            const track = this.getTrack(user.trackId);
            if (track instanceof Track) {
                data[0].track = track.title;
            }
        }
        return data;
    }

    get commentRequired() {

        const user = this.selectedUser;

        return user.isATrainee() && user.workspace.isATrainee() &&
                !_.isNull(user.traineeStatus) && user.traineeStatus.id !== user.workspace.traineeStatus.id &&
                _.contains([
                    'On Hold',
                    'Dropped',
                    'Withdrawn',
                    'Deceased'
                ], user.workspace.traineeStatus.status);
    }

    errorState(field) {
        switch (field) {
            case 'ubcId':
                //UBC ID is required for trainees and should be validated whenever an entry exists
                if (this.selectedUser.workspace.isATrainee() || !!this.selectedUser.workspace.ubcId) {
                    //Validate UBC ID db call has been made for new users or users with a new UBC ID
                    const ubcIdNeedsValidation = !_.isEqual(formatUbcId(this.selectedUser.ubcId),
                        formatUbcId(this.selectedUser.workspace.ubcId));
                    if (this.selectedUser.workspace.isNew || ubcIdNeedsValidation) {
                        return this.validatedUbcId && !this.selectedUser.workspace.errors[field];
                    } else {
                        return !this.selectedUser.workspace.errors[field];
                    }
                } else {
                    return null;
                }

            case 'vaccineVerified':
                //If the user is a trainee that will be registered for a P1 session upon saving, ensure they are
                //vaccine verified if the P1 session requires a vaccine
                if (this.selectedUser.workspace.isATrainee() && this.isNewTrainee) {
                    const track = _.find(this.availableTracks, (t) => t.id === this.traineeTrackId) || {};
                    const vaccineRequired = (track.program1 || {}).requireVaccine;
                    const vaccineVerified = this.selectedUser.workspace.vaccineVerified;
                    return !vaccineRequired || vaccineVerified;
                } else {
                    return null;
                }

            case 'phone':
                return Phone.REGEX.test(this.phoneNumber);

            case 'traineeStatusChangeReason':
                return this.showTraineeStatusChangeReason && !_.isEmpty(this.selectedUser.workspace.traineeStatusChangeReason);

            case 'traineeStatusComments':
                try {
                    const key = 'trainee-status-change-comments';
                    if (!_.has(this.$refs, key)) {
                        return true;
                    }
                    return !this.$refs[key].hasError();
                } catch (ignore) {
                    return true;
                }

            default:
                return !this.selectedUser.workspace.errors[field];
        }
    }

    getTrack(id) {
        return this.$store.getters['tracks/getTrackById'](id);
    }

    startEditing() {
        this.selectedUser.editMode = true;
    }

    cancelEditing() {

        if (this.selectedUser.isNewUser()) {
            this.$router.go(-1);
        } else {
            this.selectedUser.rollback();
            this.uname = this.selectedUser.username;
            this.phoneNumber = this.selectedUser.phone.formatted;
        }
    }

    oshaUrl(tvcHash) {
        const baseHtml = '<a href="https://train%s.carpenters.org/tvc?x=%s" target="_blank">OSHA TVC Card</a>';
        const env = this.$store.getters.appInfo.environment;
        switch (env) {
            case 'prod':
                return sprintf(baseHtml, '', tvcHash);
            case 'test':
            default:
                return sprintf(baseHtml, '-test', tvcHash);
        }
    }

    async lookupUbcId() {
        try {
            const ubcId = this.selectedUser.workspace.ubcId;
            //Valid UBC ID?
            if (!UBCID_REGEX.test(ubcId)) {
                this.validatedUbcId = false;
                await this.$bvModal.msgBoxOk('Please enter a valid UBC ID', {
                    title: 'Invalid UBC ID',
                    size: 'sm'
                });
                return;
            }
            //Lookup member by UBC ID
            const member = await memberDao.lookupMember(this.selectedUser.workspace.ubcId,
                this.selectedUser.workspace.isATrainee());
            // console.log(member);
            //No (valid) member found
            if (NULL_MEMBER.equals(member)) {
                this.validatedUbcId = false;
                await this.$bvModal.msgBoxOk('No Member could be found with that UBC ID.', {
                    title: 'Invalid UBC ID',
                    size: 'sm'
                });
                return;
            } else {

                const person = this.selectedUser.workspace;

                if (!member.username) {
                    member.username = person.username;
                }

                if (!member.title) {
                    member.title= person.title
                }

                if (!member.email) {
                    member.email=person.email
                }

                if (!_.isObject(member.emergencyContact) || member.emergencyContact.id == 0) {
                    member.emergencyContact = person.emergencyContact
                }

                if (!_.isObject(member.phone) || member.phone.id == 0) {
                    member.phone = person.phone
                }
            }
            //Existing user found
            const oldUbcId = formatUbcId(this.selectedUser.workspace.ubcId);
            const newUbcId = formatUbcId(this.selectedUser.ubcId);
            if (0 < member.userProfileId && !_.isEqual(oldUbcId, newUbcId)) {
                return this.$router.push({name:'profileView', params: {id: member.userProfileId, activeTabIndex: 0}});
            }
            //Trainee eligibility checks
            if (this.selectedUser.workspace.isATrainee()) {
                //Check trainee local union status
                if (!member.inGoodStanding) {
                    this.validatedUbcId = false;
                    await this.$bvModal.msgBoxOk('The Member is not in good standing and ' +
                        'therefore not eligible to enroll in SCT as a Trainee.', {
                        title: 'Not In Good Standing',
                        size: 'sm'
                    });
                    return;
                }
                //Check trainee OSHA 30 status
                if (!member.oshaClassScheduleId) {
                    // const tvcUrl = !!member.tvcHash ? sprintf('(%s)', this.oshaUrl(member.tvcHash)) : '';
                    const override = await this.$bvModal.msgBoxConfirm('Could not find OSHA 30 ' +
                        'Construction pre-requisite training record for Member. Please review Member\'s TVC ' +
                        'to confirm. Select OK to override and enroll with manually confirmed training.', {
                        title: 'Missing OSHA Prerequisite',
                        size: 'sm',
                        okTitle: 'Manual Override'
                    });
                    if (!override) {
                        this.validatedUbcId = false;
                        await this.$bvModal.msgBoxOk('The Member does not have an OSHA 30 Construction ' +
                            'pre-requisite and therefore not eligible to enroll in SCT as a Trainee.', {
                            title: 'NMissing OSHA Prerequisite',
                            size: 'sm'
                        });
                        return;
                    }
                }
            }
            //If no fallouts, then pull in lookup data
            this.validatedUbcId = true;
            this.selectedUser.workspace.personId = member.personId;
            this.selectedUser.workspace.firstName = member.firstName;
            this.selectedUser.workspace.middleName = member.middleName;
            this.selectedUser.workspace.lastName = member.lastName;
            this.selectedUser.workspace.suffix = member.suffix;
            this.selectedUser.workspace.title = member.title;
            this.selectedUser.workspace.email = member.email;
            this.selectedUser.workspace.phone = member.phone;
            this.selectedUser.workspace.local = this.selectedUser.local = member.local;
            this.selectedUser.workspace.emergencyContact = member.emergencyContact;
            this.selectedUser.workspace.classification = this.selectedUser.classification = member.classification;
            this.selectedUser.workspace.unionStatus = this.selectedUser.unionStatus = member.unionStatus;
            this.selectedUser.workspace.vaccineVerified = this.selectedUser.vaccineVerified = member.vaccineVerified;
            this.selectedUser.workspace.vaccineVerifiedByUsername = this.selectedUser.vaccineVerifiedByUsername = member.vaccineVerifiedByUsername;
            this.selectedUser.workspace.vaccineVerifiedDate = this.selectedUser.vaccineVerifiedDate = member.vaccineVerifiedDate;
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async saveUser() {
        // console.log(this.selectedUser.workspace);
        this.selectedUser.workspace.phone.formatted = this.phoneNumber;
        this.selectedUser.workspace.username = this.uname;
        if (this.selectedUser.workspace.hasErrors || this.ubcIdValidationNeeded ||
            (this.showTraineeStatusChangeReason && !this.errorState('traineeStatusChangeReason')) ||
            (this.commentRequired && this.$refs['trainee-status-change-comments'].hasError())
        ) {
            return this.$bvModal.msgBoxOk('Please correct the highlighted errors before saving.');
        }
        if (this.selectedUser.isNewTrainee) {
            //Enrollment notification for new trainee
            const p1 = this.traineeTrack.program1;
            const enrollCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                'Select OK to enroll the trainee in session %s. ' +
                'To go back and select a different session, select Cancel.',
                sprintf('%s (%s)', p1.label, p1.availableSeatsOnlyLabel)), {
                title: 'Confirm Enrollment',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
            if (!enrollCheck) {
                return;
            }
        }
        if (this.selectedUser.isNew) {
            return this.doSaveUser();
        }
        switch (true) {
            //User edit validation is currently owned by middleware (UserController.java)
            case this.selectedUser.isAnAdministrator():
                return this.doSaveUser();
            case this.selectedUser.isAnInstructor():
                return this.doSaveUser();
            case this.selectedUser.isAMentor():
                return this.doSaveUser();
            case this.selectedUser.isATrainee():
                this.selectedUser.workspace.comment = this.commentRequired ?
                    this.$refs['trainee-status-change-comments'].editComment : null;
                this.doSaveUser();
            default:
                return;
        }
    }

    async doSaveUser() {
        try {
            const isNewUser = this.selectedUser.isNew;
            const savedUser = await userDao.save(this.selectedUser.workspace);
            //Update all items that may have been altered by the SP
            this.selectedUser.workspace.id = savedUser.id;
            this.selectedUser.workspace.statusId = savedUser.statusId;
            this.selectedUser.workspace.status = savedUser.status;
            this.selectedUser.workspace.emergencyContact = savedUser.emergencyContact;
            this.selectedUser.workspace.registrations = savedUser.registrations;
            if (savedUser.isAMentor()) {
                //Re-check for past trainee details
                await this.$store.dispatch('users/pastTraineeDetails', savedUser.id);
            }
            if (this.selectedUser.isATrainee()) {
                const mentor = _.find(this.allMentors, (m) => m.id === this.selectedUser.mentorId);
                this.$store.commit('mentors/setMentor', mentor);
            }
            if (isNewUser) {
                this.selectedUser.rollback();
                return this.$router.push({name:'profileView', params: {id: savedUser.id, activeTabIndex: 0}});
            }
            else {
                this.selectedUser.commit();
                this.$emit('editSaved');
            }
            if (!this.activeUser.isAnAdministrator() && !_.isUndefined(this.$refs['comments-for-user'])) {
                this.$refs['comments-for-user'].refresh();
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async resetPassword() {
        const user = this.selectedUser;
        this.$bvModal.msgBoxConfirm(sprintf("Are you sure you want to reset %s %s's password?", user.firstName, user.lastName), {
            title: 'Reset Password Confirmation',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelVariant: 'secondary',
            cancelTitle: 'No',
            centered: true
        }).then(async (yesClicked) => {
            if (yesClicked) {
                try {
                    await userSessionDao.resetPassword(user.username);
                }
                catch (error) {
                    await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                }
            }
        });
    }

    get username() {
        return this.selectedUser.workspace.username;
    }

    set username(value) {
        this.uname = value;
    }

    @Watch("user")
    userChanged() {
        //This is necessary because ProfileTable doesn't re-mount when routing from one user to another in the User
        //Profile module (e.g. Trainee > Mentor)
        this.username = this.selectedUser.username;
    }

    async mounted() {
        // console.log(this.selectedUser);
        if (!this.selectedUser.workspace.securityLevel.isValid()) {
            this.selectedUser.workspace.securityLevel = SecurityLevel.TRAINEE;
        }
        this.securityLevel = this.selectedUser.workspace.securityLevel;
        this.phoneNumber = this.selectedUser.phone.formatted;
        this.username = this.selectedUser.username;
        this.validatedUbcId = !this.ubcIdIsEditable;
        this.editStatus = !this.selectedUser.workspace.isNew;
    }
}
</script>

<style>
    fieldset.profile-field div.invalid-feedback {
        text-align: left !important;
    }
    span.ubc-id-error {
        color: red;
        font-size: x-small;
    }
</style>
<template>
    <div>
        <b-collapse :id="'mentor-schedule-'+mentor.id" :visible="true">
            <registrations-snapshot
                    :user="mentor"
                    :trainees="trainees" />
        </b-collapse>
        <!--REGISTRATION MODAL-->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 title="Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <UserRegistration
                    :user="mentor"
                    @done="closeRegistration" />
        </b-modal>
        <!--RULES MODAL-->
        <b-modal :id="rulesModalId"
                 size="xl"
                 scrollable
                 ok-only
                 button-size="sm"
                 title="Registration Rules"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <registration-rules />
        </b-modal>
    </div>
</template>

<script>
import {Prop, Vue, Component} from 'vue-property-decorator';
import {Mentor, User, NULL_USER} from '@/model/user';
import _ from 'underscore';
import RegistrationsSnapshot from '@/views/private/users/snippet/RegistrationsSnapshot.vue';
import RegistrationRules from '@/views/private/users/RegistrationRules.vue';
import UserRegistration from '@/views/private/users/UserRegistration.vue';
import store from '@/store/store';

@Component({
    components: {
        RegistrationsSnapshot,
        UserRegistration,
        RegistrationRules
    }
})
export default class MentorProgramSnapshot extends Vue {
    @Prop({type: [Mentor, User]}) mentor;
    @Prop({type: Array}) trainees;

    get role() {
        return this.mentor.securityLevel;
    }

    get registrationModalId() {
        if (_.isNull(this.mentor) || _.isEqual(this.mentor, NULL_USER) ||
            _.isNull(this.mentor.id) || _.isNaN(this.mentor.id) || 0 === this.mentor.id) {
            return 'mentor-registration-modal-invalid';
        }
        else {
            return 'mentor-registration-modal-' + this.mentor.id;
        }
    }

    get rulesModalId() {
        if (_.isNull(this.mentor) || _.isEqual(this.mentor, NULL_USER) ||
            _.isNull(this.mentor.id) || _.isNaN(this.mentor.id) || 0 === this.mentor.id) {
            return 'mentor-rules-modal-invalid';
        }
        else {
            return 'mentor-rules-modal-' + this.mentor.id;
        }
    }

    register() {
        this.$bvModal.show(this.registrationModalId);
    }

    async closeRegistration() {
        this.$bvModal.hide(this.registrationModalId);
    }

    showRules() {
        this.$bvModal.show(this.rulesModalId);
    }

    get isRegistrable() {
        if (_.isNull(this.mentor) || _.isEqual(this.mentor, NULL_USER) ||
            _.isNull(this.mentor.id) || _.isNaN(this.mentor.id) || 0 === this.mentor.id) {
            return false;
        }
        return this.$store.getters['registration/isRegistrableUserId'](this.mentor.id);
    }
}
</script>
<style scoped>

</style>
import { render, staticRenderFns } from "./MentorProgramSnapshot.vue?vue&type=template&id=92891fd2&scoped=true&"
import script from "./MentorProgramSnapshot.vue?vue&type=script&lang=js&"
export * from "./MentorProgramSnapshot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92891fd2",
  null
  
)

export default component.exports
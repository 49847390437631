<template>
    <div>
        <br />
        <b-button-group>
            <b-button
                    variant="primary"
                    v-if="isRegistrableUser && !isTraineeUser"
                    @click="showRegistration">Register</b-button>
            <b-button
                    variant="primary"
                    v-else
                    @click="showRules">Why Can't I Register?</b-button>
        </b-button-group>
        <br /><br />
        <ul class="registration-schedule">
            <li v-for="reg in schedule">
                <b-link
                        @click="getDetails(reg.session)">{{reg.session.programLabel}}</b-link>
                <span> - </span>
                <b-dropdown
                        :variant="statusVariant(reg.status)"
                        :text=reg.status
                        size="sm">
                    <!--It takes too long for the store calls to return for UI rendering registration option verification-->
                    <!--These steps are delegated to the called methods-->
                    <template v-if="isRegistered(reg.status) && !!reg.hasRegistrationId && !isTraineeUser">
                        <b-dropdown-item
                                @click="resend(reg)">Resend confirmation</b-dropdown-item>
                        <b-dropdown-item
                                @click="cancel(reg)">Cancel registration</b-dropdown-item>
                        <b-dropdown-item
                                v-if="allowCompleteIncomplete"
                                @click="incomplete(reg)">Mark incomplete</b-dropdown-item>
                    </template>
                    <template v-else-if="(isScheduled(reg.status) || (isCancelled(reg.status) && !reg.session.canceled)) && isRegistrableUser && !isTraineeUser">
                        <b-dropdown-item
                                @click="register(reg)">Register</b-dropdown-item>
                    </template>
                    <template v-else-if="isCompleted(reg.status) && !!reg.hasRegistrationId && allowCompleteIncomplete && !isTraineeUser">
                        <b-dropdown-item
                                @click="incomplete(reg)">Mark incomplete</b-dropdown-item>
                    </template>
                    <template v-else-if="isIncomplete(reg.status) && !!reg.hasRegistrationId && allowCompleteIncomplete && !isTraineeUser">
                        <b-dropdown-item
                                @click="complete(reg)">Mark complete</b-dropdown-item>
                    </template>
                    <template v-else>
                        <b-dropdown-item>No actions available</b-dropdown-item>
                    </template>
                </b-dropdown>
                <br />
                <template v-if="reg.status == 'unknown'">
                    Program information is not available
                </template>
                <template v-else>
                    {{reg.session.dates[0] | programDate}} - {{reg.session.dates[1] | programDate}}
                </template>
                <br />
            </li>
        </ul>
        <!-- SESSION DETAILS MODAL -->
        <b-modal :id="sessionDetailsModalId"
                 size="xl"
                 scrollable
                 ok-only
                 title="Session Details"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <SessionDetails
                    :session="session" />
        </b-modal>
        <!--REGISTRATION MODAL-->
        <b-modal :id="registrationModalId"
                 size="xl"
                 scrollable
                 title="Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <UserRegistration
                    :user="user"
                    @done="closeRegistration" />
        </b-modal>
        <!--RULES MODAL-->
        <b-modal :id="rulesModalId"
                 size="xl"
                 scrollable
                 ok-only
                 button-size="sm"
                 title="Registration Rules"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <registration-rules />
        </b-modal>
        <!-- CONFIRM REGISTRATION MODAL -->
        <b-modal :id="confirmRegistrationModalId"
                 size="xl"
                 scrollable
                 noCloseOnBackdrop
                 noCloseOnEsc
                 hideHeaderClose
                 hideFooter
                 title="Confirm Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <ConfirmRegistration
                    :registrationRequest="registrationRequest"
                    :requestType="requestType"
                    @registered="registered"
                    @canceled="canceled" />
        </b-modal>
    </div>
</template>

<script>
import {Vue, Component, Prop} from 'vue-property-decorator';
import _ from 'underscore';
import store from '@/store/store';
import {format as dateFormat} from 'date-fns';
import {sprintf} from 'sprintf-js';
import {NULL_USER, User, Trainee, Mentor} from "@/model/user";
import {Status, RegistrationRequestType} from "@/model/registration";
import ConfirmRegistration from "@/views/private/tracks/ConfirmRegistration.vue";
import UserRegistration from '@/views/private/users/UserRegistration.vue';
import RegistrationRules from '@/views/private/users/RegistrationRules.vue';
import SessionDetails from '@/views/private/tracks/SessionDetails.vue';
import registrationDao from "@/dao/registration_dao";
import {errorModalOptions, errorToastOptions} from "../../../../util/formatters";
import covidRegistrationRestriction from '@/errors/covid_registration_restriction';

@Component({
    components: {
        ConfirmRegistration,
        UserRegistration,
        RegistrationRules,
        SessionDetails
    },
    filters: {
        programDate: (date) => _.isDate(date) ? dateFormat(date, 'M/d/yyyy') : ''
    },
    asyncComputed: {
        isRegistrableUser: {
            async get() {
                if (!User.isValidUser(this.user)) {
                    return false;
                }
                try {
                    await store.dispatch('registration/loadRegistrableUserIds');
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                return store.getters['registration/isRegistrableUserId'](this.user.id);
            }
        },
        isCancelableUser: {
            async get() {
                if (!User.isValidUser(this.user)) {
                    return false;
                }
                try {
                    await store.dispatch('registration/loadCancelableUserIds');
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
                return store.getters['registration/isCancelableUserId'](this.user.id);
            }
        },
        schedule: {
            async get() {
                if (!User.isValidUser(this.user)) {
                    return [];
                }
                //Get mentor schedule
                if (this.user.isAMentor()) {
                    try {
                        await store.dispatch('registration/loadMentorSchedule', {
                            mentor: this.user,
                            trainees: this.trainees
                        });
                    }
                    catch (error) {
                        console.log(error);
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const schedule = store.getters['registration/mentorSchedule'](this.user.id);
                    return schedule;
                }
                //Get trainee schedule
                else if (this.user.isATrainee()) {
                    try {
                        await store.dispatch('registration/loadTraineeSchedule', this.user);
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const schedule = store.getters['registration/traineeSchedule'](this.user.id);
                    if (!this.user.isEnrolled) {
                        //Don't show scheduled sessions for non-enrolled
                        const filteredSchedule = _.filter(schedule, item => {
                            return !this.isScheduled(item.status);
                        });
                        return filteredSchedule;
                    }
                    else {
                        return schedule;
                    }
                }
                //Invalid user
                else {
                    return [];
                }
            }
        }
    },
    methods: {
        refreshSchedule() {
            this.refreshUser();
            this.$asyncComputed.schedule.update();
            this.$asyncComputed.isRegistrableUser.update();
            this.$asyncComputed.isCancelableUser.update();
        }
    }
})

export default class RegistrationsSnapshot extends Vue {
    @Prop({type: [Mentor, Trainee, User], default: NULL_USER}) user;
    @Prop({type: Array, default: function() { return [] }}) trainees;
    registrationRequest = null;
    session = null;

    get isTraineeUser() {
        const activeUser = store.getters['userSession/getUser'];
        return activeUser.isATrainee();
    }

    get sessionDetailsModalId() {
        if (_.isNull(this.session)) {
            return 'session-details-modal-invalid';
        }
        else {
            return sprintf('session-details-modal-%d', this.session.sessionId);
        }
    }

    getDetails(session) {
        this.session = session;
        this.$bvModal.show(this.sessionDetailsModalId);
    }

    get confirmRegistrationModalId() {
        if (!User.isValidUser(this.user)) {
            return 'confirm-registration-modal-invalid';
        }
        else {
            return 'confirm-registration-modal-' + this.user.id;
        }
    }

    get registrationModalId() {
        if (!User.isValidUser(this.user)) {
            return 'user-registration-modal-invalid';
        }
        else {
            return 'user-registration-modal-' + this.user.id;
        }
    }

    get rulesModalId() {
        if (!User.isValidUser(this.user)) {
            return 'reg-rules-invalid';
        }
        else {
            return 'reg-rules-modal-' + this.user.id;
        }
    }

    showRegistration() {
        this.$bvModal.show(this.registrationModalId);
    }

    async closeRegistration() {
        this.refreshSchedule();
        this.$bvModal.hide(this.registrationModalId);
    }

    showRules() {
        this.$bvModal.show(this.rulesModalId);
    }

    registered() {
        this.refreshSchedule();
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    canceled() {
        this.$bvModal.hide(this.confirmRegistrationModalId);
        this.registrationRequest = null;
    }

    get requestType() {
        return RegistrationRequestType.SESSION_REGISTRATION;
    }

    async mounted() {
        // console.log(this.user);
    }

    async refreshUser() {
        try {
            await store.dispatch('users/loadUser', this.user.id);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
        const updatedUser = store.getters['users/user'];
        this.user.registrations = updatedUser.registrations;
    }

    statusVariant(status) {
        return Status.variant(status);
    }

    isRegistered(status) {
        return _.isEqual(status, Status.REGISTERED);
    }

    isScheduled(status) {
        return _.isEqual(status, Status.SCHEDULED);
    }

    isCancelled(status) {
        return _.isEqual(status, Status.CANCELED);
    }

    isCompleted(status) {
        return _.isEqual(status, Status.COMPLETED);
    }

    isIncomplete(status) {
        return _.isEqual(status, Status.INCOMPLETE);
    }

    //Don't offer the option to mark a session registration incomplete unless user is admin and registrant is trainee
    get allowCompleteIncomplete() {
        return this.user.isATrainee() && store.getters['userSession/isAnAdmin'];
    }

    async incomplete(scheduleItem) {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to make this session registration incomplete?', {
                title: 'Confirm Incomplete',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        //Update registration - note that only one of complete/incomplete should have a value
        //Consider implementing workspace
        const oldComplete = scheduleItem.registration.complete;
        const oldIncomplete = scheduleItem.registration.incomplete;
        scheduleItem.registration.complete = null;
        scheduleItem.registration.incomplete = new Date();
        try {
            await this.$store.dispatch('registration/processRegistration', scheduleItem, {root: true});
            this.refreshSchedule();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            scheduleItem.registration.complete = oldComplete;
            scheduleItem.registration.incomplete = oldIncomplete;
        }
    }

    async complete(scheduleItem) {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you wish to make this session registration completed?', {
                title: 'Confirm Completed',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        //Update registration - note that only one of complete/incomplete should have a value
        //Consider implementing workspace
        const oldComplete = scheduleItem.registration.complete;
        const oldIncomplete = scheduleItem.registration.incomplete;
        scheduleItem.registration.complete = new Date();
        scheduleItem.registration.incomplete = null;
        try {
            await this.$store.dispatch('registration/processRegistration', scheduleItem, {root: true});
            this.refreshSchedule();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            scheduleItem.registration.complete = oldComplete;
            scheduleItem.registration.incomplete = oldIncomplete;
        }
    }

    async resend(scheduleItem) {
        const registrationId = scheduleItem.registration.id;
        try {
            const success = registrationId ? await registrationDao.sendConfirmation(registrationId) : false;
            if (success) {
                await this.$bvModal.msgBoxOk('Confirmation sent');
            }
            else {
                await this.$bvModal.msgBoxOk('Confirmation could not be sent at this time');
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async cancel(scheduleItem) {
        if (!this.isCancelableUser || !this.isCancellable(scheduleItem)) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to cancel registration for this session.');
        }
        else {
            const cancelCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                'Are you sure you wish to cancel the registration for user %s for session %s? ' +
                'Any related travel requests will be canceled as well.',
                scheduleItem.user.fullname, scheduleItem.session.label), {
                title: 'Confirm Registration Cancellation',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                hideHeaderClose: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
            if (!cancelCheck) {
                console.log('Cancellation aborted!');
                return;
            }
            scheduleItem.registration.canceled = true;
            try {
                await this.$store.dispatch('registration/cancelRegistration', scheduleItem, {root: true});
                this.refreshSchedule();
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                scheduleItem.registration.canceled = false;
            }
        }
    }

    async register(scheduleItem) {
        // console.log(scheduleItem);
        if (!this.isRegistrableUser || !(await this.isRegistrable(scheduleItem))) {
            await this.$bvModal.msgBoxOk('Sorry, user is not eligible to register for this session.');
        }
        else {
            //Check for override
            if (scheduleItem.needsOverride) {
                const activeUser = store.getters['userSession/getUser'];
                if (activeUser.isAnAdministrator()) {
                    const check = await this.$bvModal.msgBoxConfirm('The participant you are attempting to ' +
                        'register for a session that requires vaccine verification has not been verified. Would you ' +
                        'like to override the vaccine requirement and register this participant anyway?', {
                        title: 'Vaccine Override Needed',
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        headerBgVariant: 'dark',
                        headerTextVariant: 'white'
                    });
                    if (check) {
                        //Set override flag
                        scheduleItem.registration.vaccineOverride = true;
                    } else {
                        return;
                    }
                } else {
                    //A non-admin cannot override
                    return this.$bvModal.msgBoxOk(covidRegistrationRestriction(this), {
                        size: 'md',
                        buttonSize: 'sm'
                    });
                }
            }
            this.registrationRequest = scheduleItem;
            this.registrationRequest.registration.canceled = false;
            this.$bvModal.show(this.confirmRegistrationModalId);
        }
    }

    isCancellable(scheduleItem) {
        if (!!scheduleItem.registration.id && scheduleItem.status === Status.REGISTERED) {
            return scheduleItem.registration.cancelable;
        }
        //Non-registered statuses can't cancel
        else {
            return false;
        }
    }

    async isRegistrable(scheduleItem) {
        if (_.isEqual(scheduleItem.status, Status.SCHEDULED) || _.isEqual(scheduleItem.status, Status.CANCELED)) {
            try {
                await store.dispatch('registration/loadRegistrableSessionsByUser', scheduleItem.user);
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
            const registrableSessionsForUser = this.$store.getters['registration/registrableSessionsByUser'](scheduleItem.user);
            return !!_.find(registrableSessionsForUser, session => session.sessionId === scheduleItem.session.sessionId);
        }
        //Non-scheduled statuses can't register (at least in this context)
        else {
            return false;
        }
    }
}

</script>
<style scoped>
    ul.registration-schedule {
        margin: 0;
        padding: 0 0 0 1.25rem;
    }
</style>
